import { colors } from '../../core/stylesConfig'

export const Box = {
  backgroundColor: colors.common.white,
  borderRadius: 1,
  boxShadow: colors.shadow.min,
  padding: 2,
  margin: '6px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: 2
}

export const Modal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: 4
}
