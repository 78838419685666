import { colors } from '../../core/stylesConfig'

export const Container = {
  display: 'flex',
  minHeight: '98vh',
  alignItems: 'center',
  justifyContent: 'center'
}

export const Box = {
  maxWidth: '400px',
  marginTop: 10,
  marginBottom: 5,
  width: '90vw',
  backgroundColor: `${colors.common.white}`,
  borderRadius: 3,
  padding: {
    xs: '20px',
    sm: '35px'
  }
}

export const Login = {
  fontSize: '1.5rem',
  marginBottom: '1rem'
}

export const Input = {
  marginBottom: '20px'
}

export const LogoBackground = {
  position: 'absolute',
  bottom: '0%',
  left: '-18%',
  width: 'auto',
  height: '85%',
  zIndex: '-1',
  filter: 'blur(12px)',
  overflow: 'hidden',
  objectFit: 'center'
}

export const Logo = {
  position: 'absolute',
  top: '15px',
  left: '15px',
  width: 'auto',
  height: '50px'
}

export const BoxLogoBg = {
  position: 'absolute',
  top: 0,
  maxWidth: '100vw',
  maxHeight: '100vh',
  width: '100%',
  zIndex: '-1',
  height: '100%',
  overflow: 'hidden'
}
