import React from 'react'
import { uniqueId } from 'lodash'

import MuiBox from '@mui/material/Box'
import MuiCheckbox from '@mui/material/Checkbox'
import MuiFormControl from '@mui/material/FormControl'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import MuiFormGroup from '@mui/material/FormGroup'
import MuiFormHelperText from '@mui/material/FormHelperText'
import MuiFormLabel from '@mui/material/FormLabel'
import MuiGrid from '@mui/material/Grid'
import MuiInputLabel from '@mui/material/InputLabel'
import MuiMenuItem from '@mui/material/MenuItem'
import MuiRadio from '@mui/material/Radio'
import MuiRadioGroup from '@mui/material/RadioGroup'
import MuiSelect from '@mui/material/Select'
import MuiTextField from '@mui/material/TextField'
import MuiTypography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import * as validators from '../../utils/validators'
import * as mask from '../../utils/mask'

export function TextShow(props) {
  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <MuiBox
        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}
      >
        <MuiTypography variant="h5" sx={{ marginRight: '5px' }}>
          {props.field.label}:
        </MuiTypography>
        <MuiTypography variant="h6">
          {mask[props.field.mask](props.value)}
        </MuiTypography>
      </MuiBox>
    </MuiGrid>
  )
}

export function Question(props) {
  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <MuiBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <MuiTypography variant="h5" sx={{ marginRight: '5px' }}>
          {props.field.label}:
        </MuiTypography>
        <MuiTextField
          fullWidth
          size="small"
          type="text"
          variant="outlined"
          sx={{ display: 'flex', textAlign: 'center' }}
          error={
            props.isSubmit && validators[props.field.validator](props.value).err
          }
          helperText={
            props.isSubmit && validators[props.field.validator](props.value).msg
          }
          value={mask[props.field.mask](props.value)}
          onChange={e => {
            props.onChange(
              props.field.name,
              validators[props.field.validator](e.target.value).value
            )
          }}
        />
      </MuiBox>
    </MuiGrid>
  )
}

export function TextField(props) {
  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <MuiTextField
        fullWidth
        size="small"
        type="text"
        variant="outlined"
        label={props.field.label}
        sx={{ display: 'flex', textAlign: 'center' }}
        error={
          props.isSubmit && validators[props.field.validator](props.value).err
        }
        helperText={
          props.isSubmit && validators[props.field.validator](props.value).msg
        }
        value={mask[props.field.mask](props.value)}
        onChange={e => {
          props.onChange(
            props.field.name,
            validators[props.field.validator](e.target.value).value
          )
        }}
      />
    </MuiGrid>
  )
}

export function Password(props) {
  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <MuiTextField
        fullWidth
        size="small"
        type={showPassword ? 'text' : 'password'}
        variant="outlined"
        label={props.field.label}
        error={
          props.isSubmit && validators[props.field.validator](props.value).err
        }
        helperText={
          props.isSubmit && validators[props.field.validator](props.value).msg
        }
        value={mask[props.field.mask](props.value)}
        onChange={e => {
          props.onChange(
            props.field.name,
            validators[props.field.validator](e.target.value).value
          )
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </MuiGrid>
  )
}

export function Select(props) {
  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <MuiFormControl
        fullWidth
        size="small"
        error={
          props.isSubmit && validators[props.field.validator](props.value).err
        }
      >
        <MuiInputLabel>{props.field.label}</MuiInputLabel>
        <MuiSelect
          label={props.field.label}
          value={props.value}
          onChange={e => props.onChange(props.field.name, e.target.value)}
        >
          <MuiMenuItem key={uniqueId()} value={''}>
            -----
          </MuiMenuItem>
          {props.field?.data &&
            props.field?.data.map(element => (
              <MuiMenuItem key={element.uuid} value={element.uuid}>
                {element.name}
              </MuiMenuItem>
            ))}
        </MuiSelect>
        <MuiFormHelperText>
          {props.isSubmit && validators[props.field.validator](props.value).msg}
        </MuiFormHelperText>
      </MuiFormControl>
    </MuiGrid>
  )
}

export function DependentSelects(props) {
  const [stateValue, setStateValue] = React.useState('')
  const [cityValue, setCityValue] = React.useState('')
  const estados = props.citiesData.estados

  const handleStateChange = (_, value) => {
    setStateValue(value)
    setCityValue('') // Reset the value for the city select
    props.onChange('state', value)
  }

  const handleCityChange = (_, value) => {
    setCityValue(value)
    props.onChange('city', value)
  }

  const citiesForSelectedState =
    estados.find(state => state.sigla === stateValue)?.cidades || []

  return (
    <>
      <Select
        field={{
          ...props.stateField,
          data: estados.map(estado => ({
            name: estado.sigla,
            uuid: estado.sigla
          }))
        }}
        value={stateValue}
        onChange={handleStateChange}
        isSubmit={props.isSubmit}
      />
      <Select
        field={{
          ...props.cityField,
          data: citiesForSelectedState.map(city => ({ name: city, uuid: city }))
        }}
        value={cityValue}
        onChange={handleCityChange}
        isSubmit={props.isSubmit}
      />
    </>
  )
}

export function RadioButton(props) {
  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <MuiFormControl>
        <MuiFormLabel>
          <MuiTypography variant="h4">{props.field.label}</MuiTypography>
        </MuiFormLabel>
        <MuiRadioGroup
          value={props.value}
          onChange={e => props.onChange(props.field.name, e.target.value)}
        >
          {props.field?.data &&
            props.field?.data.map(element => (
              <MuiFormControlLabel
                key={element.uuid}
                value={element.uuid}
                control={<MuiRadio />}
                label={element.name}
              />
            ))}
        </MuiRadioGroup>
      </MuiFormControl>
    </MuiGrid>
  )
}

export function CheckBox(props) {
  const [state, setState] = React.useState({})

  const handleChange = event => {
    const aux = {
      ...state,
      [event.target.name]: event.target.checked
    }
    return aux
  }

  React.useEffect(() => {
    if (props.value) {
      setState(props.value)
    }
  }, [props.value])

  React.useEffect(() => {
    if (props.field.data) {
      let aux = {}
      for (let i = 0; i < props.field.data.length; i++) {
        aux = {
          ...aux,
          [props.field.data[i].uuid]: false
        }
      }
      setState(aux)
    }
  }, [])

  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <MuiFormControl>
        <MuiFormLabel>
          <MuiTypography variant="h4">{props.field.label}</MuiTypography>
        </MuiFormLabel>
        <MuiFormGroup>
          {props.field?.data &&
            props.field?.data.map(element => (
              <MuiFormControlLabel
                key={element.uuid}
                control={
                  <MuiCheckbox
                    checked={state[element.uuid] || false}
                    onChange={e => {
                      const value = handleChange(e)
                      props.onChange(props.field.name, value)
                    }}
                    name={element.uuid}
                  />
                }
                label={element.name}
              />
            ))}
        </MuiFormGroup>
      </MuiFormControl>
    </MuiGrid>
  )
}
