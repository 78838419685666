import React, { useEffect } from 'react'
import SwipeableViews from 'react-swipeable-views'
import PDFModal from './PDFModal'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import { IconButton } from '@mui/material'
import AssignmentIcon from '@mui/icons-material/Assignment'

import api from '../../services/api'
import Alert from '../../components/Alert'
import QuestionReview from '../../components/QuestionReview'
import Table from '../../components/Table'

import * as styles from './styles'

const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const restructureData = inputData => {
  const output = []
  // Filtrar os principais títulos
  const mainTitles = inputData.filter(item => item.options.length === 0)

  mainTitles.forEach(title => {
    const sectionNumber = title.group.split('.')[0] // Pega o número do título principal

    const relatedData = inputData.filter(
      item => item.group.startsWith(sectionNumber) && item.options.length !== 0
    )

    output.push({
      group: title.group.replace(/^\d+\.\d+ /, ''), // Remove números do título
      statement: title.statement,
      data: relatedData
    })
  })

  return output
}

const a11yProps = index => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const index = () => {
  const theme = useTheme()

  const [value, setValue] = React.useState(0)
  const [questions, setQuestions] = React.useState([])
  const [respForm, setRespForm] = React.useState([])

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const [pdfString, setPdfString] = React.useState('')
  const [isOpenPdfModal, setIsOpenPdfModal] = React.useState(false)

  const [uniqueId, setUniqueId] = React.useState(null)
  const [deleteUuid, setDeleteUuid] = React.useState(null)
  const [rows, setRows] = React.useState([])

  const [valueAvaliation, setValueAvaliation] = React.useState([])
  const [specialistassignment, setSpecialistassignment] = React.useState([])

  const handleChange = (_event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  const filterQuestions = (list, label) => {
    const temp = list.find(e =>
      e.group.toLowerCase().includes(label.toLowerCase())
    )

    return temp
  }

  const filterResp = questionUuid => {
    const tempData = respForm.find(e => e.question_uuid === questionUuid)

    return tempData ? tempData.option_uuid : ''
  }

  const filterEvidence = questionUuid => {
    const tempData = respForm.find(e => e.question_uuid === questionUuid)

    if (tempData && tempData?.evidence && tempData.evidence !== null) {
      return tempData?.evidence
    } else {
      return null
    }
  }

  const formatPhoneNumber = number => {
    const strNumber = number.toString()

    if (strNumber.length === 13) {
      return `+${strNumber.slice(0, 2)} (${strNumber.slice(
        2,
        4
      )}) ${strNumber.slice(4, 9)}-${strNumber.slice(9, 13)}`
    } else if (strNumber.length === 12) {
      return `+${strNumber.slice(0, 2)} (${strNumber.slice(
        2,
        4
      )}) ${strNumber.slice(4, 8)}-${strNumber.slice(8, 12)}`
    } else if (strNumber.length === 11) {
      return `+55 (${strNumber.slice(0, 2)}) ${strNumber.slice(
        2,
        7
      )}-${strNumber.slice(7, 11)}`
    } else if (strNumber.length === 10) {
      return `+55 (${strNumber.slice(0, 2)}) ${strNumber.slice(
        2,
        6
      )}-${strNumber.slice(6, 10)}`
    } else if (strNumber.length === 9) {
      return `+55 (XX) ${strNumber.slice(0, 4)}-${strNumber.slice(4, 8)}`
    } else if (strNumber.length === 8) {
      return `+55 (XX) ${strNumber.slice(0, 3)}-${strNumber.slice(3, 7)}`
    } else {
      return 'Número inválido'
    }
  }

  const formatCNPJ = n => {
    return `${n.slice(0, 2)}.${n.slice(2, 5)}.${n.slice(5, 8)}/${n.slice(
      8,
      12
    )}-${n.slice(12)}`
  }

  const columns = [
    {
      field: 'Ações',
      headerName: 'Ações',
      width: 60,
      renderCell: params => (
        <div>
          {filterSpecialist(params.row.uuid) === false ? (
            <IconButton onClick={() => setOpenModal(true)}>
              <AssignmentIcon size={24} color="#2f2fd3" />
            </IconButton>
          ) : (
            <IconButton
              color="success"
              onClick={() => {
                handleSubmit(params.row.uuid, params.row.answers)
              }}
            >
              <AssignmentIcon size={24} />
            </IconButton>
          )}
        </div>
      )
    },
    { field: 'trade_name', headerName: 'Empresa', width: 200 },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      width: 150,
      renderCell: params => {
        if (params.row.cnpj) {
          return formatCNPJ(params.row.cnpj)
        }
      }
    },
    { field: 'full_name_responsible', headerName: 'Responsável', width: 200 },
    {
      field: 'phone',
      headerName: 'Telefone',
      width: 150,
      renderCell: params => {
        if (params.row.phone) {
          return formatPhoneNumber(params.row.phone)
        }
      }
    },
    { field: 'email_responsible', headerName: 'Email', width: 250 },
    {
      field: 'average',
      headerName: 'Média',
      width: 90,
      renderCell: () => {
        return <>Avaliando</>
      }
    }
  ]

  const handleSubmit = async (uuid, answer, type = 'show') => {
    if (type === 'show') {
      if (answer.length > 0) {
        setMsg('')
        setUniqueId(uuid)
        try {
          setTypeAlert('loading')
          setOpenAlert(true)

          const tempUserUuid = localStorage.getItem('inovaMT@form')
          if (tempUserUuid) {
            const userUuid = JSON.parse(tempUserUuid).user.uuid

            const response = await api.get('/answer', {
              params: {
                all: true,
                atributo: 'company_uuid',
                value: uuid
              }
            })

            if (response?.data) {
              setRespForm(response.data)
              setOpenAlert(false)
            }

            const response2 = await api.get('/specialistevaluation', {
              params: {
                all: true,
                atributo: 'evaluated_by',
                value: userUuid
              }
            })

            if (response?.data && response2?.data) {
              const tempEvaluation = []

              for (let i = 0; i < response.data.length; i++) {
                const temp = response2.data.find(
                  e => e.answer_uuid === response.data[i].uuid
                )

                if (temp) {
                  tempEvaluation.push(temp)
                }
              }

              setValueAvaliation(tempEvaluation)
            }
          }
        } catch (err) {
          setMsg('Não foi possível adquirir as Respostas!')
          setTypeAlert('error')
        }
      } else {
        setMsg('Formulário ainda não preenchido!')
        setTypeAlert('alert')
        setOpenAlert(true)
      }
    } else {
      setDeleteUuid(uuid)
      setMsg('Essa operação é irreverssível, deseja continuar?')
      setTypeAlert('delete')
      setOpenAlert(true)
    }
  }

  const handleDelete = async () => {
    try {
      setMsg('')
      setTypeAlert('loading')

      await api.delete('/user/', { params: { uuid: deleteUuid } })

      setMsg('Usuário excluido com sucesso!')
      setTypeAlert('success')
    } catch (err) {
      setMsg('Erro ao excluir o usuário, tente novamente!')
      setTypeAlert('error')
    }
  }

  const filterSpecialist = uuid => {
    const tempFilter = specialistassignment.find(
      item => item.company_uuid === uuid
    )

    return tempFilter ? tempFilter.concluded : false
  }

  const filterValueAvaliation = uuid => {
    const tempValue = valueAvaliation.find(item => item.answer_uuid === uuid)

    return tempValue ? tempValue.evaluation : 0
  }

  const filterValueUuid = uuid => {
    const tempValue = valueAvaliation.find(item => item.answer_uuid === uuid)

    return tempValue ? tempValue.uuid : null
  }

  const filterValueJustify = uuid => {
    const tempValue = valueAvaliation.find(item => item.answer_uuid === uuid)

    return tempValue ? tempValue.editing_reason : ''
  }

  const filterAnswerUuid = questionUuid => {
    const tempData = respForm.find(e => e.question_uuid === questionUuid)

    return tempData ? tempData.uuid : ''
  }

  const loadData = async () => {
    setTypeAlert('loading')
    setOpenAlert(true)

    try {
      let resp = await api.get('/company/', { params: { all: true } })

      const session = localStorage.getItem('inovaMT@form')
      if (session) {
        const sessionJson = JSON.parse(session)
        if (sessionJson.user) {
          const uuid = sessionJson.user.uuid
          const resp2 = await api.get('/specialistassignment', {
            params: { all: true, atributo: 'specialist_uuid', value: uuid }
          })

          if (
            resp2?.data &&
            resp2.data.length > 0 &&
            resp?.data &&
            resp.data.length > 0
          ) {
            setSpecialistassignment(resp2.data)

            const tempCompanies = resp.data
            let filterCompanies = []

            resp2.data.forEach(object => {
              const tempData = tempCompanies.filter(
                item => item.uuid === object.company_uuid
              )
              if (tempData) {
                filterCompanies = filterCompanies.concat(tempData)
              }
            })

            setRows(filterCompanies)
          }
        }
      }

      resp = await api.get('/question/', { params: { all: true } })
      if (resp) {
        const temp = restructureData(resp.data)
        setQuestions(temp)
        setOpenAlert(false)
      }
    } catch (err) {
      setMsg('Não foi possível adquirir os dados!')
      setTypeAlert('error')
    }
  }

  const loadFile = (base64File, fileType) => {
    const byteCharacters = atob(base64File)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: fileType })
    const blobUrl = URL.createObjectURL(blob)
  
    window.open(blobUrl)
    URL.revokeObjectURL(blobUrl)
  }

  const handlePdfView = (evidence) => {
    if (evidence != null) {
      // setPdfString(evidence)
      // setIsOpenPdfModal(true)
      loadFile(evidence, 'application/pdf')
    }
  }

  const moveProx = () => {
    setValue(value + 1)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  const moveAnt = () => {
    setValue(value - 1)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  // 006.096.540-12

  useEffect(() => {
    loadData()
  }, [])

  return (
    <Box>
      {uniqueId !== null && (
        <Box sx={{ display: 'flex', gap: 1, maxWidth: '100px' }}>
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            size="large"
            type="submit"
            variant="contained"
            onClick={() => setUniqueId(null)}
          >
            Voltar
          </Button>
        </Box>
      )}
      {uniqueId !== null ? (
        questions.length > 0 && (
          <Box sx={styles.Box}>
            <Box
              sx={{
                maxWidth: {
                  xs: 320,
                  sm: '100vw',
                  md: '100vw',
                  lg: '100vw',
                  xl: '100vw'
                }
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="inherit"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Auto Diagnóstico" {...a11yProps(0)} />
                <Tab label="Conhecimento do Mercado" {...a11yProps(1)} />
                <Tab label="Liderança Transformadora e Intenção de Inovar" {...a11yProps(2)} />
                <Tab label="Gestão de Projetos e Pessoas para inovação" {...a11yProps(3)} />
                <Tab label="Gestão estratégica de tecnologias e organicidade" {...a11yProps(4)} />
                <Tab label="Avaliação de desempenho e resultados de inovação" {...a11yProps(5)} />
                {/* <Tab label="Melhoria" {...a11yProps(6)} /> */}
              </Tabs>
            </Box>

            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <>
                  <QuestionReview
                    title={
                      filterQuestions(questions, 'Auto Diagnóstico')
                        .group.replace(/^\d\. /, '') || ''
                    }
                    value={
                      filterQuestions(questions, 'Auto Diagnóstico')
                        .statement || ''
                    }
                    type="text"
                  />
                  <br />
                  {filterQuestions(
                    questions,
                    'Auto Diagnóstico'
                  ).data.map((q, index) => (
                    <>
                      <QuestionReview
                        key={index}
                        title={q.statement}
                        options={q.options}
                        uuid={q.uuid}
                        type="radio-zero"
                        resp={filterResp(q.uuid)}
                        setResp={() => {}}
                        typeButtonFile={true}
                        evidence={filterEvidence(q.uuid)}
                        updateEvidence={handlePdfView}
                        answerUuid={filterAnswerUuid(q.uuid)}
                        avaliationValue={filterValueAvaliation(
                          filterAnswerUuid(q.uuid)
                        )}
                        justify={filterValueJustify(filterAnswerUuid(q.uuid))}
                        evaluationUUid={filterValueUuid(
                          filterAnswerUuid(q.uuid)
                        )}
                      />
                      <br />
                    </>
                  ))}
                </>
              </TabPanel>

              <TabPanel value={value} index={1} dir={theme.direction}>
                <>
                  <QuestionReview
                    title={filterQuestions(questions, 'Conhecimento do Mercado').group || ''}
                    value={
                      filterQuestions(questions, 'Conhecimento do Mercado').statement || ''
                    }
                    type="text"
                  />
                  <br />
                  {filterQuestions(questions, 'Conhecimento do Mercado').data.map(
                    (q, index) => (
                      <>
                        <QuestionReview
                          key={index}
                          title={q.statement}
                          options={q.options}
                          uuid={q.uuid}
                          type="radio"
                          resp={filterResp(q.uuid)}
                          setResp={() => {}}
                          typeButtonFile={true}
                          evidence={filterEvidence(q.uuid)}
                          updateEvidence={handlePdfView}
                          answerUuid={filterAnswerUuid(q.uuid)}
                          avaliationValue={filterValueAvaliation(
                            filterAnswerUuid(q.uuid)
                          )}
                          justify={filterValueJustify(filterAnswerUuid(q.uuid))}
                          evaluationUUid={filterValueUuid(
                            filterAnswerUuid(q.uuid)
                          )}
                        />
                        <br />
                      </>
                    )
                  )}
                </>
              </TabPanel>

              <TabPanel value={value} index={2} dir={theme.direction}>
                <>
                  <QuestionReview
                    title={
                      filterQuestions(questions, 'Liderança Transformadora e Intenção de Inovar').group || ''
                    }
                    value={
                      filterQuestions(questions, 'Liderança Transformadora e Intenção de Inovar').statement || ''
                    }
                    type="text"
                  />
                  <br />
                  {filterQuestions(questions, 'Liderança Transformadora e Intenção de Inovar').data.map(
                    (q, index) => (
                      <>
                        <QuestionReview
                          key={index}
                          title={q.statement}
                          options={q.options}
                          uuid={q.uuid}
                          type="radio"
                          resp={filterResp(q.uuid)}
                          setResp={() => {}}
                          typeButtonFile={true}
                          evidence={filterEvidence(q.uuid)}
                          updateEvidence={handlePdfView}
                          answerUuid={filterAnswerUuid(q.uuid)}
                          avaliationValue={filterValueAvaliation(
                            filterAnswerUuid(q.uuid)
                          )}
                          justify={filterValueJustify(filterAnswerUuid(q.uuid))}
                          evaluationUUid={filterValueUuid(
                            filterAnswerUuid(q.uuid)
                          )}
                        />
                        <br />
                      </>
                    )
                  )}
                </>
              </TabPanel>

              <TabPanel value={value} index={3} dir={theme.direction}>
                <>
                  <QuestionReview
                    title={filterQuestions(questions, 'Gestão de Projetos e Pessoas para inovação').group || ''}
                    value={
                      filterQuestions(questions, 'Gestão de Projetos e Pessoas para inovação').statement || ''
                    }
                    type="text"
                  />
                  <br />
                  {filterQuestions(questions, 'Gestão de Projetos e Pessoas para inovação').data.map(
                    (q, index) => (
                      <>
                        <QuestionReview
                          key={index}
                          title={q.statement}
                          options={q.options}
                          uuid={q.uuid}
                          type="radio"
                          resp={filterResp(q.uuid)}
                          setResp={() => {}}
                          typeButtonFile={true}
                          evidence={filterEvidence(q.uuid)}
                          updateEvidence={handlePdfView}
                          answerUuid={filterAnswerUuid(q.uuid)}
                          avaliationValue={filterValueAvaliation(
                            filterAnswerUuid(q.uuid)
                          )}
                          justify={filterValueJustify(filterAnswerUuid(q.uuid))}
                          evaluationUUid={filterValueUuid(
                            filterAnswerUuid(q.uuid)
                          )}
                        />
                        <br />
                      </>
                    )
                  )}
                </>
              </TabPanel>

              <TabPanel value={value} index={4} dir={theme.direction}>
                <>
                  <QuestionReview
                    title={filterQuestions(questions, 'Gestão estratégica de tecnologias e organicidade').group || ''}
                    value={
                      filterQuestions(questions, 'Gestão estratégica de tecnologias e organicidade').statement || ''
                    }
                    type="text"
                  />
                  <br />
                  {filterQuestions(questions, 'Gestão estratégica de tecnologias e organicidade').data.map(
                    (q, index) => (
                      <>
                        <QuestionReview
                          key={index}
                          title={q.statement}
                          options={q.options}
                          uuid={q.uuid}
                          type="radio"
                          resp={filterResp(q.uuid)}
                          setResp={() => {}}
                          typeButtonFile={true}
                          evidence={filterEvidence(q.uuid)}
                          updateEvidence={handlePdfView}
                          answerUuid={filterAnswerUuid(q.uuid)}
                          avaliationValue={filterValueAvaliation(
                            filterAnswerUuid(q.uuid)
                          )}
                          justify={filterValueJustify(filterAnswerUuid(q.uuid))}
                          evaluationUUid={filterValueUuid(
                            filterAnswerUuid(q.uuid)
                          )}
                        />
                        <br />
                      </>
                    )
                  )}
                </>
              </TabPanel>

              <TabPanel value={value} index={5} dir={theme.direction}>
                <>
                  <QuestionReview
                    title={
                      filterQuestions(questions, 'Avaliação de desempenho e resultados de inovação')
                        .group || ''
                    }
                    value={
                      filterQuestions(questions, 'Avaliação de desempenho e resultados de inovação')
                        .statement || ''
                    }
                    type="text"
                  />
                  <br />
                  {filterQuestions(
                    questions,
                    'Avaliação de desempenho e resultados de inovação'
                  ).data.map((q, index) => (
                    <>
                      <QuestionReview
                        key={index}
                        title={q.statement}
                        options={q.options}
                        uuid={q.uuid}
                        type="radio"
                        resp={filterResp(q.uuid)}
                        setResp={() => {}}
                        typeButtonFile={true}
                        evidence={filterEvidence(q.uuid)}
                        updateEvidence={handlePdfView}
                        answerUuid={filterAnswerUuid(q.uuid)}
                        avaliationValue={filterValueAvaliation(
                          filterAnswerUuid(q.uuid)
                        )}
                        justify={filterValueJustify(filterAnswerUuid(q.uuid))}
                        evaluationUUid={filterValueUuid(
                          filterAnswerUuid(q.uuid)
                        )}
                      />
                      <br />
                    </>
                  ))}
                </>
              </TabPanel>

              {/* <TabPanel value={value} index={6} dir={theme.direction}>
                <>
                  <QuestionReview
                    title={filterQuestions(questions, 'Melhoria').group || ''}
                    value={
                      filterQuestions(questions, 'Melhoria').statement || ''
                    }
                    type="text"
                  />
                  <br />
                  {filterQuestions(questions, 'Melhoria').data.map(
                    (q, index) => (
                      <>
                        <QuestionReview
                          key={index}
                          title={q.statement}
                          options={q.options}
                          uuid={q.uuid}
                          type="radio"
                          resp={filterResp(q.uuid)}
                          setResp={() => {}}
                          typeButtonFile={true}
                          evidence={filterEvidence(q.uuid)}
                          updateEvidence={handlePdfView}
                          answerUuid={filterAnswerUuid(q.uuid)}
                          avaliationValue={filterValueAvaliation(
                            filterAnswerUuid(q.uuid)
                          )}
                          justify={filterValueJustify(filterAnswerUuid(q.uuid))}
                          evaluationUUid={filterValueUuid(
                            filterAnswerUuid(q.uuid)
                          )}
                        />
                        <br />
                      </>
                    )
                  )}
                </>
              </TabPanel> */}
            </SwipeableViews>
          </Box>
        )
      ) : (
        <Table rows={rows} columns={columns} handleSubmit={handleSubmit} />
      )}

      <Box sx={{ display: 'flex', gap: 1 }}>
        {value !== 0 ? (
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            size="large"
            type="submit"
            variant="contained"
            onClick={() => moveAnt()}
          >
            Anterior
          </Button>
        ) : (
          <></>
        )}
        {value !== 6 && uniqueId !== null && (
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            size="large"
            type="submit"
            variant="contained"
            onClick={() => moveProx()}
          >
            Proximo
          </Button>
        )}
        {value === 6 && (
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            size="large"
            type="submit"
            variant="contained"
            onClick={() => {}}
          >
            Atribuir
          </Button>
        )}
      </Box>

      <Dialog open={openModal} aria-labelledby="responsive-dialog-title">
        <Box sx={styles.Dialog}>
          <Typography variant="h5">
            Você não avaliou esse formulário!
          </Typography>
          <Button variant="contained" onClick={() => setOpenModal(false)}>
            Confirmar
          </Button>
        </Box>
      </Dialog>

      <Alert
        open={openAlert}
        setOpen={setOpenAlert}
        alert={typeAlert}
        msg={msg}
        delete={handleDelete}
        success={() => {
          setOpenAlert(false)
          window.location.reload()
        }}
      />

      {/* <PDFModal
        base64String={pdfString}
        isOpen={isOpenPdfModal}
        onClose={() => setIsOpenPdfModal(false)}
      /> */}
    </Box>
  )
}

export default index
