import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import { Box, Button, TextField, Typography } from '@mui/material'

import Alert from '../../../components/Alert'
import { routes } from '../../../routes/data'

const index = props => {
  const navigate = useNavigate()

  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const handleSubmitPassword = async () => {
    if (password === password2) {
      setMsg('')
      setTypeAlert('loading')
      setOpenAlert(true)

      try {
        const resp = await axios.put(
          `${process.env.REACT_APP_API_URL}/new-password/`,
          { password },
          {
            headers: {
              Authentication: props.token
            }
          }
        )

        if (resp) {
          props.setToken(resp.data.token)
          setPassword('')
          setPassword2('')
          setMsg('Senha alterada com sucesso!')
          setTypeAlert('success')
        }
      } catch (err) {
        if (err?.response?.data) {
          setMsg(err.response.data.detail[0].msg)
        } else {
          setMsg('Ocorreu um erro!')
        }
        setTypeAlert('error')
      }
    } else {
      setMsg('As senhas devem ser iguais')
      setTypeAlert('error')
      setOpenAlert(true)
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Typography>Digite sua nova senha</Typography>
        <TextField
          fullWidth
          size="small"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Typography>Repita a senha</Typography>
        <TextField
          fullWidth
          size="small"
          value={password2}
          onChange={e => setPassword2(e.target.value)}
        />
        <Button fullWidth variant="contained" onClick={handleSubmitPassword}>
          Trocar
        </Button>
      </Box>

      <Alert
        open={openAlert}
        setOpen={setOpenAlert}
        alert={typeAlert}
        msg={msg}
        success={() => navigate(routes.login)}
        delete={() => {}}
      />
    </>
  )
}

export default index
