import FormGroup from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'

import React from 'react'

import CloseIcon from '@mui/icons-material/Close'

import Logo from '../../assets/logoazul.png'

import * as styles from './styles'

const index = props => {
  const [open, setOpen] = React.useState(false)
  return (
    <FormGroup sx={styles.Group}>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.checked}
            onChange={e => props.setChecked(e.target.checked)}
          />
        }
        label={<Typography>Li e aceito os </Typography>}
      />
      <Button onClick={() => setOpen(!open)}>Termos e Condições</Button>
      <Dialog open={open} onClose={() => setOpen(!open)} sx={styles.Dialog}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: 2,
            marginBottom: 2,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
          }}
        >
          <CloseIcon
            sx={{ position: 'absolute', left: '20px' }}
            onClick={() => setOpen(!open)}
          />
          <Box
            component="img"
            sx={{
              width: '100px'
            }}
            src={Logo}
          />
        </Box>
        <Typography sx={{ textAlign: 'justify', margin: '0 0 8px 8px' }}>
          Para este formulário será necessário a coleta de dados pessoais e dados sensíveis dos representantes e da empresa. Essas informações serão armazenadas nas bases de dados das instituições governamentais responsáveis pela iniciativa: Governo do Estado de Mato Grosso através da SECITECI - Secretaria de Estado de Ciência, Tecnologia e Inovação, CNPJ: 03.507.415/0024-30, Rua Mistral, 457 - Jardim Bom Clima, CEP 78048-135, Cuiabá, Mato Grosso, do Parque Tecnológico de Mato Grosso, Av. Projetada A - Passagem da Conceição, CEP 78158-720, Várzea Grande - Mato Grosso, e da FAPEMAT - Fundação de Amparo à Pesquisa do Estado de Mato Grosso, CNPJ: 02.357.455/0001-94, Av. Tenente Coronel Duarte, 1278 - Centro Sul, CEP 78020-450, Cuiabá, Mato Grosso.
        </Typography>
      </Dialog>
    </FormGroup>
  )
}

export default index
