import { Routes, Route, BrowserRouter } from 'react-router-dom'

import * as Pages from '../pages'

import { routes } from './data'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.login} element={<Pages.Login />} />
        <Route path={routes.cadastrar} element={<Pages.Cadastrar />} />
        <Route path={routes.trocar_senha} element={<Pages.TrocarSenha />} />
        <Route path={routes.home} element={<Pages.Home />}>
          <Route path={routes.formulario} element={<Pages.Formulario />} />
          <Route path={routes.formfull} element={<Pages.FormFull />} />
          <Route path={routes.listar} element={<Pages.Listar />} />
          <Route
            path={routes.listar_atribuidas}
            element={<Pages.ListarAtribuidas />}
          />
          <Route path={routes.dashboard} element={<Pages.Dashboard />} />
          <Route path={routes.ranking} element={<Pages.Ranking />} />
          <Route path={routes.avaliar} element={<Pages.Avaliar />} />
          <Route path={routes.avaliadas} element={<Pages.Avaliadas />} />
          <Route path={routes.cadastrar_user} element={<Pages.CadUser />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
