import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
// import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'

import UploadFileIcon from '@mui/icons-material/UploadFile'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import * as styles from './styles'

// Este componente tem por finalidade estruturar cada quetão do formulário separadamente
// Props:
// type -> a propriedade 'type' é responsável por definir qual é o tipo de input que será exibido
// ---- possíveis valores: 'text', 'select'
// value -> define o valor pré definido do campo
// setValue -> define a função que resgata o valor do campo
// evidence -> define o valor pré definido do campo
// setEvidence -> define a função que resgata o valor do campo
// options -> a propriedade 'options' é responsável por receber um array com valores para um 'type: select'
// title -> a propriedade 'title' é responsável por receber o titulo ou enunciado da questão

const index = props => {
  const [tempFile, setTempFile] = React.useState(null)
  const verificValue = () => {
    const selectedIndex = props.options?.findIndex(
      options => options.uuid === props.resp
    )
    return selectedIndex > 1
  }

  function extractNumber(text) {
    const match = text.match(/^\d+/)
    return match ? parseInt(match[0], 10) : 0;
  }
  
  const sortedOptions = props?.options ? props.options.sort((a, b) => {
    return extractNumber(a.text) - extractNumber(b.text);
  }) : []

  const handleDownload = () => {
    if (props.evidence) {
      // Converte a string Base64 em um Blob
      const byteCharacters = atob(props.evidence)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: 'application/pdf' })

      // Cria um Object URL e aciona um evento de clique para fazer o download
      const objectUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = objectUrl
      a.download = `${Date.now().toString()}.pdf`
      a.click()
      URL.revokeObjectURL(objectUrl)
    }
  }

  if (props.type === 'text') {
    return (
      <Box sx={styles.Question}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {props.title}
        </Typography>
        <Typography>{props.value}</Typography>
      </Box>
    )
  } else if (props.type === 'select') {
    return (
      <Box sx={styles.Question}>
        <Typography>{props.title}</Typography>
        <Box sx={styles.Input}>
          <FormControl fullWidth size="small">
            <Select label={props.label}>
              {props.options.map(data => (
                <MenuItem value={data.uuid} key={data.uuid}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            component="label"
            startIcon={<CloudUploadIcon />}
            variant="contained"
          >
            Subir Evidência (PDF)
            <styles.InputFile
              onClick={e => props.setEvidence(e.target.value)}
              type="file"
            />
          </Button>
          <IconButton color={props.evidence === true ? 'success' : 'default'}>
            <UploadFileIcon />
          </IconButton>
        </Box>
      </Box>
    )
  } else if (props.type === 'radio') {
    return (
      <Box sx={styles.Question}>
        <Typography sx={styles.QuestionTitle}>{props.title}</Typography>
        <Box sx={styles.Input}>
          <FormControl
            sx={
              verificValue() && !props?.evidence && props.evidence === null
                ? { color: '#ee0000' }
                : { color: 'black' }
            }
            fullWidth
            size="small"
          >
            <RadioGroup
              value={props.resp}
              onChange={e => {
                props.setResp(e.target.value, props.uuid, props.options)
              }}
            >
              {props?.options &&
                sortedOptions.map(data => (
                  <FormControlLabel
                    value={data.uuid}
                    control={<Radio />}
                    label={data.text}
                    key={data.uuid}
                  />
                ))}
            </RadioGroup>
            {verificValue() && !props?.evidence && props.evidence === null ? (
              <Typography>
                Para esta alternativa é necessário subir uma evidência
              </Typography>
            ) : (
              <div></div>
            )}
          </FormControl>
          <Box>
            <Box sx={{ display: 'flex' }}>
              {props?.typeButtonFile && props.typeButtonFile === true ? (
                <Button
                  component="label"
                  startIcon={<CloudUploadIcon />}
                  variant="contained"
                  disabled={props.evidence == null || props.evidence === ''}
                  onClick={() => props.updateEvidence(props.evidence)}
                >
                  Visualizar Evidência
                </Button>
              ) : (
                <>
                  <Button
                    component="label"
                    startIcon={<CloudUploadIcon />}
                    variant="contained"
                  >
                    Subir Evidência (PDF)
                    <styles.InputFile
                      onChange={e => {
                          setTempFile(e.target.files[0])
                          props.updateEvidence(e.target.files[0], props.uuid)
                        }
                      }
                      type="file"
                    />
                  </Button>
                </>
              )}
              <IconButton
                color={
                  props?.evidence && props.evidence !== null ? 'success' : 'default'
                }
                onClick={handleDownload}
              >
                <UploadFileIcon />
              </IconButton>
            </Box>
            <Typography sx={{ textAlign: 'center', marginTop: 0.5, fontSize: '13px', color: tempFile?.size && (tempFile.size / (1024 * 1024)).toFixed(2) > 10 ? 'red': '#AAA'}}>Tamanho máximo de 10 MB. - {tempFile?.size ? (tempFile.size / (1024 * 1024)).toFixed(2): '0'} / 10</Typography>
          </Box>
        </Box>
        <Divider textAlign="center" sx={{ margin: '1rem 0 2rem 0' }} />
      </Box>
    )
  } else if (props.type === 'radio-zero') {
    return (
      <Box sx={styles.Question}>
        <Typography sx={styles.QuestionTitle}>{props.title}</Typography>
        <Box sx={styles.Input}>
          <FormControl
            sx={{ color: 'black' }}
            fullWidth
            size="small"
          >
            <RadioGroup
              value={props.resp}
              onChange={e => {
                props.setResp(e.target.value, props.uuid, props.options)
              }}
            >
              {props?.options &&
                sortedOptions.map(data => (
                  <FormControlLabel
                    value={data.uuid}
                    control={<Radio />}
                    label={data.text}
                    key={data.uuid}
                  />
                ))}
            </RadioGroup>
          </FormControl>
        </Box>
        <Divider textAlign="center" sx={{ margin: '1rem 0 2rem 0' }} />
      </Box>
    )
  }
}

export default index
