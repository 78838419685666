import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import * as styles from './styles'

const index = () => {
  return (
    <Box>
      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
        Orientações para o Preenchimento do Questionário
      </Typography>
      <Box sx={styles.Box}>
        <Typography sx={{ margin: '0 0 0 8px' }}>
          O questionário está dividido em duas partes.
        </Typography>
        <Typography sx={{ margin: '0 0 0 8px' }}>
          1ª Parte
        </Typography>
        <Typography sx={{ margin: '0 0 0 8px', fontWeight: 'bold' }}>
          Sobre as estratégias de inovação da empresa.
        </Typography>
        <Typography sx={{ margin: '0 0 0 8px' }}>
          São 4 questões que buscam identificar a prioridade das estratégias da 
          sua empresa atualmente e, assim, verificar qual o tipo de inovação a 
          empresa tem como objetivo em seu negócio. Para essas questões, basta 
          assinalar a opção mais próxima à sua realidade. Apenas uma opção por questão.
        </Typography>
        <Typography sx={{ margin: '0 0 0 8px' }}>
          2ª Parte
        </Typography>
        <Typography sx={{ margin: '0 0 0 8px', fontWeight: 'bold' }}>
          Sobre as Práticas de Gestão da Inovação na Empresa.
        </Typography>
        <Typography sx={{ margin: '0 0 0 8px' }}>
          Em 5 pilares (Fatores Determinantes da Capacidade de Inovação), estão 
          organizadas 17 questões que visam identificar o nível de maturidade das 
          práticas de gestão da inovação da empresa.
        </Typography>
        <Typography sx={{ margin: '0 0 0 8px' }}>
          Para essas questões, a empresa deve optar pela alternativa que melhor 
          represente em que nível a atividade é realizada na empresa.
        </Typography>
        <Typography sx={{ margin: '0 0 0 8px' }}>
          Abaixo está o conceito para cada nível na escala do questionário de 1 a 5, 
          sendo (1) nível INFORMAL e (5) SISTEMATIZADO E MELHORADO CONTINUAMENTE. 
          A partir da alternativa de nível (3) PROATIVA E ESTRUTURADA, 
          a empresa precisa incluir documentos de comprovação das práticas no formato PDF – EVIDÊNCIAS.
        </Typography>
        <Typography sx={{ margin: '0 0 0 8px' }}>
          Em caso de não inclusão de evidências, a resposta será desconsiderada 
          para o processo de avaliação.
        </Typography>
      </Box>
    </Box>
  )
}

export default index
