import React from 'react'

import Box from '@mui/material/Box'

import api from '../../services/api'
import Alert from '../../components/Alert'
import Table from '../../components/Table'

const Ranking = () => {
  const [rows, setRows] = React.useState([])

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const formatPhoneNumber = number => {
    const strNumber = number.toString()

    if (strNumber.length === 13) {
      return `+${strNumber.slice(0, 2)} (${strNumber.slice(
        2,
        4
      )}) ${strNumber.slice(4, 9)}-${strNumber.slice(9, 13)}`
    } else if (strNumber.length === 12) {
      return `+${strNumber.slice(0, 2)} (${strNumber.slice(
        2,
        4
      )}) ${strNumber.slice(4, 8)}-${strNumber.slice(8, 12)}`
    } else if (strNumber.length === 11) {
      return `+55 (${strNumber.slice(0, 2)}) ${strNumber.slice(
        2,
        7
      )}-${strNumber.slice(7, 11)}`
    } else if (strNumber.length === 10) {
      return `+55 (${strNumber.slice(0, 2)}) ${strNumber.slice(
        2,
        6
      )}-${strNumber.slice(6, 10)}`
    } else if (strNumber.length === 9) {
      return `+55 (XX) ${strNumber.slice(0, 4)}-${strNumber.slice(4, 8)}`
    } else if (strNumber.length === 8) {
      return `+55 (XX) ${strNumber.slice(0, 3)}-${strNumber.slice(3, 7)}`
    } else {
      return 'Número inválido'
    }
  }

  const formatMedia = num => {
    if (isNaN(num)) {
      return '0,00'
    } else {
      const strNum = `${num.slice(0, 1)},${num.slice(1, 3)}`
      return `${strNum}`
    }
  }

  const formatCNPJ = n => {
    return `${n.slice(0, 2)}.${n.slice(2, 5)}.${n.slice(5, 8)}/${n.slice(
      8,
      12
    )}-${n.slice(12)}`
  }

  const columns = [
    {
      field: 'pos',
      headerName: 'Posição',
      width: 150,
      renderCell: params => {
        return <>{params.row.pos}</>
      }
    },
    { field: 'trade_name', headerName: 'Empresa', width: 300 },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      width: 200,
      renderCell: params => {
        if (params.row.cnpj) {
          return formatCNPJ(params.row.cnpj)
        }
      }
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      width: 200,
      renderCell: params => {
        if (params.row.phone) {
          return formatPhoneNumber(params.row.phone)
        }
      }
    },
    {
      field: 'evaluation',
      headerName: 'Média',
      width: 100,
      renderCell: params => {
        if (params.row.evaluation) {
          return formatMedia(params.row.evaluation)
        } else {
          return 0
        }
      }
    }
  ]

  const loadData = async () => {
    try {
      setTypeAlert('loading')
      setOpenAlert(true)

      const response = await api.get('/company/', { params: { all: true } })
      const response2 = await api.get('/answer/no-evidence/', {
        params: { all: true }
      })
      const response3 = await api.get('/specialistevaluation/', {
        params: { all: true }
      })

      if (response?.data && response2?.data && response3?.data) {
        const tempCompanies = []
        for (let i = 0; i < response.data.length; i++) {
          const tempData = response2.data.filter(
            item => item.company_uuid === response.data[i].uuid
          )

          if (tempData) {
            let tempFormEvaluation = []
            for (let j = 0; j < tempData.length; j++) {
              const tempEvaluation = response3.data.filter(
                item => item.answer_uuid === tempData[j].uuid
              )

              if (tempEvaluation) {
                tempFormEvaluation = tempFormEvaluation.concat(tempEvaluation)
              }
            }

            const tempMedia = tempFormEvaluation.reduce(
              (sum, obj) => sum + obj.evaluation,
              0
            )
            const num = tempMedia / tempFormEvaluation.length
            const number = num.toFixed(2).toString()
            const numberString = `${number.slice(0, 1)}${number.slice(2, 4)}`
            tempCompanies.push({
              ...response.data[i],
              evaluation: numberString
            })
          }
        }

        const orderedCompanies = tempCompanies
          .filter(i => !isNaN(i.evaluation))
          .sort((a, b) => b.evaluation - a.evaluation)

        const orderedCompaniesWithPosition = orderedCompanies.map(
          (company, index) => ({
            ...company,
            pos: index + 1
          })
        )
        setRows(
          orderedCompaniesWithPosition.filter(item => !isNaN(item.evaluation))
        )
      }

      setOpenAlert(false)
    } catch (err) {
      setMsg('Não foi possível adquirir os dados!')
      setTypeAlert('error')
    }
  }

  React.useEffect(() => {
    loadData()
  }, [])

  return (
    <Box>
      <Table rows={rows} columns={columns} handleSubmit={() => {}} />

      <Alert
        open={openAlert}
        setOpen={setOpenAlert}
        alert={typeAlert}
        msg={msg}
        delete={() => {}}
        success={() => {
          setOpenAlert(false)
          window.location.reload()
        }}
      />
    </Box>
  )
}

export default Ranking
