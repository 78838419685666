import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CssBaseline from '@mui/material/CssBaseline'

import LogoAzul from '../../assets/logoazul.png'

import EnviarEmail from './EnviarEmail'
import ValidarCodigo from './ValidarCodigo'
import NovaSenha from './NovaSenha'

import * as styles from './styles'

const Cadastrar = () => {
  const [component, setComponent] = React.useState('EnviarEmail')

  const [token, setToken] = React.useState(null)

  return (
    <Box sx={styles.Container}>
      <CssBaseline />
      <Box src={LogoAzul} component="img" sx={styles.Logo} />
      <Box sx={styles.Box}>
        <Typography variant="h5" sx={styles.Login}>
          Trocar Senha
        </Typography>
        {component === 'EnviarEmail' && (
          <EnviarEmail setComponent={setComponent} setToken={setToken} />
        )}
        {component === 'ValidarCodigo' && (
          <ValidarCodigo
            setComponent={setComponent}
            token={token}
            setToken={setToken}
          />
        )}
        {component === 'NovaSenha' && (
          <NovaSenha
            setComponent={setComponent}
            token={token}
            setToken={setToken}
          />
        )}
      </Box>
    </Box>
  )
}

export default Cadastrar
