import React, { useEffect } from 'react'
import SwipeableViews from 'react-swipeable-views'
import PDFModal from './PDFModal'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'

import api from '../../services/api'
import Alert from '../../components/Alert'
import QuestionReview from '../../components/QuestionAvaliable'
import Table from '../../components/Table'

import * as styles from './styles'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder'
]

const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const restructureData = inputData => {
  const output = []
  // Filtrar os principais títulos
  const mainTitles = inputData.filter(item => item.options.length === 0)

  mainTitles.forEach(title => {
    const sectionNumber = title.group.split('.')[0] // Pega o número do título principal

    const relatedData = inputData.filter(
      item => item.group.startsWith(sectionNumber) && item.options.length !== 0
    )

    output.push({
      group: title.group.replace(/^\d+\.\d+ /, ''), // Remove números do título
      statement: title.statement,
      data: relatedData
    })
  })

  return output
}

const a11yProps = index => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const index = () => {
  const theme = useTheme()

  const [value, setValue] = React.useState(0)
  const [questions, setQuestions] = React.useState([])

  const [respForm, setRespForm] = React.useState([])
  const [user, setUser] = React.useState({})
  const [formExib, setFormExib] = React.useState(false)

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const [pdfString, setPdfString] = React.useState('')
  const [isOpenPdfModal, setIsOpenPdfModal] = React.useState(false)

  const [uniqueId, setUniqueId] = React.useState(null)
  const [deleteUuid, setDeleteUuid] = React.useState(null)
  const [rows, setRows] = React.useState([])

  const [filteredSpecialists, setFilteredSpecialists] = React.useState([])
  const [evaluation, setEvaluation] = React.useState([])
  const [listEvaluations, setListEvaluations] = React.useState([])
  const [selectedSpecialist, setSelectedSpecialist] = React.useState('')

  const [openModal, setOpenModal] = React.useState(false)
  const [personName, setPersonName] = React.useState([])
  const [listSpecialists, setListSpecialists] = React.useState([])
  const [listSpecialistAssignments, setListSpecialistAssignments] =
    React.useState([])

  const handleChange = (_event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  const filterQuestions = (list, label) => {
    const temp = list.find(e =>
      e.group.toLowerCase().includes(label.toLowerCase())
    )

    return temp
  }

  const filterResp = questionUuid => {
    const tempData = respForm.find(e => e.question_uuid === questionUuid)

    return tempData ? tempData.option_uuid : ''
  }

  const filterEvidence = questionUuid => {
    const tempData = respForm.find(e => e.question_uuid === questionUuid)

    if (tempData && tempData?.evidence && tempData.evidence !== null) {
      return tempData?.evidence
    } else {
      return null
    }
  }

  const formatPhoneNumber = number => {
    const strNumber = number.toString()

    if (strNumber.length === 13) {
      return `+${strNumber.slice(0, 2)} (${strNumber.slice(
        2,
        4
      )}) ${strNumber.slice(4, 9)}-${strNumber.slice(9, 13)}`
    } else if (strNumber.length === 12) {
      return `+${strNumber.slice(0, 2)} (${strNumber.slice(
        2,
        4
      )}) ${strNumber.slice(4, 8)}-${strNumber.slice(8, 12)}`
    } else if (strNumber.length === 11) {
      return `+55 (${strNumber.slice(0, 2)}) ${strNumber.slice(
        2,
        7
      )}-${strNumber.slice(7, 11)}`
    } else if (strNumber.length === 10) {
      return `+55 (${strNumber.slice(0, 2)}) ${strNumber.slice(
        2,
        6
      )}-${strNumber.slice(6, 10)}`
    } else if (strNumber.length === 9) {
      return `+55 (XX) ${strNumber.slice(0, 4)}-${strNumber.slice(4, 8)}`
    } else if (strNumber.length === 8) {
      return `+55 (XX) ${strNumber.slice(0, 3)}-${strNumber.slice(3, 7)}`
    } else {
      return 'Número inválido'
    }
  }
  const formatCNPJ = n => {
    return `${n.slice(0, 2)}.${n.slice(2, 5)}.${n.slice(5, 8)}/${n.slice(
      8,
      12
    )}-${n.slice(12)}`
  }

  const columns = [
    {
      field: 'Ações',
      headerName: 'Ações',
      width: 60,
      renderCell: params => (
        <div>
          <IconButton
            title="Visualizar"
            onClick={() => {
              handleSubmit(params.row.uuid, params.row.answers, 'info')
              setFormExib(false)
            }}
          >
            <VisibilityIcon size={24} color="#2f2fd3" />
          </IconButton>
        </div>
      )
    },
    { field: 'trade_name', headerName: 'Empresa', width: 200 },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      width: 150,
      renderCell: params => {
        if (params.row.cnpj) {
          return formatCNPJ(params.row.cnpj)
        }
      }
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      width: 150,
      renderCell: params => {
        if (params.row.phone) {
          return formatPhoneNumber(params.row.phone)
        }
      }
    },
    {
      field: 'assigned_to',
      headerName: 'Atribuido Para',
      width: 440,
      renderCell: params => {
        return <>{filterAssignedNames(params.row.uuid)}</>
      }
    }
    // {
    //   field: 'average',
    //   headerName: 'Média',
    //   width: 90,
    //   renderCell: () => {
    //     return <>Avaliando</>
    //   }
    // }
  ]

  const handleSubmit = async (uuid, answer, type = 'show') => {
    if (type === 'show') {
      if (answer.length > 0) {
        setMsg('')
        // Fazer requisição para as respostas
        try {
          setTypeAlert('loading')
          setOpenAlert(true)

          const response = await api.get('/answer', {
            params: {
              all: true,
              atributo: 'company_uuid',
              value: uuid
            }
          })

          if (response.data) {
            setRespForm(response.data)
            setUniqueId(uuid)
            setOpenAlert(false)
          }
        } catch (err) {
          setMsg('Não foi possível adquirir as Respostas!')
          setTypeAlert('error')
        }
      } else {
        setMsg('Formulário ainda não preenchido!')
        setTypeAlert('alert')
        setOpenAlert(true)
      }
    } else if (type === 'info') {
      if (answer.length > 0) {
        setMsg('')
        setUniqueId(uuid)
        // Fazer requisição para as respostas
        try {
          setTypeAlert('loading')
          setOpenAlert(true)

          const response = await api.get('/company', {
            params: {
              all: false,
              atributo: 'uuid',
              value: uuid
            }
          })

          if (response.data) {
            setUser(response?.data)
          }

          const response2 = await api.get('/answer', {
            params: {
              all: true,
              atributo: 'company_uuid',
              value: uuid
            }
          })

          if (response2?.data) {
            setRespForm(response2.data)
          }

          const response3 = await api.get('/specialistevaluation', {
            params: { all: true }
          })

          const filteredData = listSpecialistAssignments.filter(
            item => item.company_uuid === uuid
          )

          if (filteredData && filteredData.length > 0) {
            const tempList = []
            for (let i = 0; i < filteredData.length; i++) {
              const tempData = listSpecialists.find(
                item => item.uuid === filteredData[i].specialist_uuid
              )

              tempList.push(tempData)
            }
            setFilteredSpecialists(tempList)

            if (tempList.length > 0) {
              const tempEvaluations = []
              for (let i = 0; i < tempList.length; i++) {
                const tempAnswers = []
                for (let j = 0; j < response2.data.length; j++) {
                  const tempQuestion = response3.data.find(
                    item =>
                      item.evaluated_by === tempList[i].uuid &&
                      item.answer_uuid === response2.data[j].uuid
                  )

                  if (tempQuestion) {
                    tempAnswers.push(tempQuestion)
                  }
                }
                tempEvaluations.push({
                  evalueted_by: tempList[i],
                  answers: tempAnswers
                })
              }

              setListEvaluations(tempEvaluations)

              const tempEvaluationMedia = []
              if (tempEvaluations.length > 0) {
                for (let i = 0; i < tempEvaluations.length; i++) {
                  if (tempEvaluations[i].answers.length > 0) {
                    let tempSum = 0
                    for (
                      let j = 0;
                      j < tempEvaluations[i].answers.length;
                      j++
                    ) {
                      tempSum += tempEvaluations[i].answers[j].evaluation
                    }
                    tempEvaluationMedia.push({
                      evaluated_by: tempEvaluations[i].evalueted_by.name,
                      media: tempSum / tempEvaluations[i].answers.length
                    })
                  } else {
                    tempEvaluationMedia.push({
                      evaluated_by: tempEvaluations[i].evalueted_by.name,
                      media: 0
                    })
                  }
                }

                setEvaluation(tempEvaluationMedia)
              }
            }
          }

          setOpenAlert(false)
        } catch (err) {
          setMsg('Não foi possível adquirir as Respostas!')
          setTypeAlert('error')
        }
      } else {
        setMsg('Formulário ainda não preenchido!')
        setTypeAlert('alert')
        setOpenAlert(true)
      }
    } else {
      setDeleteUuid(uuid)
      setMsg('Essa operação é irreverssível, deseja continuar?')
      setTypeAlert('delete')
      setOpenAlert(true)
    }
  }

  const handleDelete = async () => {
    try {
      setMsg('')
      setTypeAlert('loading')

      await api.delete('/user/', { params: { uuid: deleteUuid } })

      setMsg('Usuário excluido com sucesso!')
      setTypeAlert('success')
    } catch (err) {
      setMsg('Erro ao excluir o usuário, tente novamente!')
      setTypeAlert('error')
    }
  }

  // function isWithin10Minutes(datetime) {
  //   const now = new Date()
  //   const differenceInMilliseconds = now - datetime
  //   const differenceInMinutes = differenceInMilliseconds / (1000 * 60)
  //   return differenceInMinutes < 60
  // }

  const loadData = async () => {
    try {
      setTypeAlert('loading')
      setOpenAlert(true)

      let resp = await api.get('/question/', { params: { all: true } })
      if (resp) {
        const temp = restructureData(resp.data)
        setQuestions(temp)
      }

      const tempRow = localStorage.getItem('inovaMT@/data')
      // const hour = localStorage.getItem('inovaMT@/hour')

      if (tempRow) {
        setRows(JSON.parse(tempRow))
        // const datetime = new Date(hour)
        // const resultado = isWithin10Minutes(datetime)

        setOpenAlert(false)
      }

      resp = await api.get('/specialistassignment', {
        params: { all: true }
      })

      if (resp?.data) {
        setListSpecialistAssignments(resp.data)
      }

      resp = await api.get('/company/', { params: { all: true } })
      if (resp) {
        localStorage.setItem('inovaMT@/data', JSON.stringify(resp.data))
        localStorage.setItem('inovaMT@/hour', new Date())

        if (tempRow !== JSON.stringify(resp.data)) {
          setRows(resp.data)
        }

        setOpenAlert(false)
      }
    } catch (err) {
      setMsg('Não foi possível adquirir os dados!')
      setTypeAlert('error')
    }
  }

  const loadSpecialist = async () => {
    try {
      setTypeAlert('loading')
      setOpenAlert(true)

      const response = await api.get('/role/', { params: { all: true } })
      if (response?.data) {
        const temp = response.data.find(e => e.role_name === 'specialist')
        if (temp) {
          const response = await api.get('/user/', {
            params: { all: true, atributo: 'role_uuid', value: temp.uuid }
          })
          if (response?.data) {
            setListSpecialists(response.data)
            setOpenAlert(false)
          }
        }
      }
    } catch (err) {
      setMsg('Não foi possível adquirir os dados!')
      setTypeAlert('error')
    }
  }

  const loadFile = (base64File, fileType) => {
    const byteCharacters = atob(base64File)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: fileType })
    const blobUrl = URL.createObjectURL(blob)
  
    window.open(blobUrl)
    URL.revokeObjectURL(blobUrl)
  }

  const handlePdfView = (evidence) => {
    if (evidence != null) {
      // setPdfString(evidence)
      // setIsOpenPdfModal(true)
      loadFile(evidence, 'application/pdf')
    }
  }
  const moveProx = () => {
    setValue(value + 1)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  const moveAnt = () => {
    setValue(value - 1)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  const filterEvaluations = uuid => {
    const tempEvaluations = listEvaluations.find(
      item => item.evalueted_by.uuid === selectedSpecialist
    )

    if (tempEvaluations) {
      const temp = tempEvaluations.answers.find(
        item => item.answer_uuid === uuid
      )

      if (temp) {
        return temp.evaluation
      }
    }

    return ''
  }

  const filterAssignedNames = uuid => {
    const filteredData = listSpecialistAssignments.filter(
      item => item.company_uuid === uuid
    )

    if (filteredData && filteredData.length > 0) {
      let tempString = ''
      for (let i = 0; i < filteredData.length; i++) {
        const tempData = listSpecialists.find(
          item => item.uuid === filteredData[i].specialist_uuid
        )
        if (i === 0 && tempData) {
          tempString += tempData.name
        } else {
          tempString += ', ' + tempData?.name
        }
      }

      return tempString
    } else {
      return ''
    }
  }

  const filterBusiness = uuid => {
    if (uuid === '1') {
      return 'Indústria'
    } else if (uuid === '2') {
      return 'Comércio'
    } else if (uuid === '3') {
      return 'Serviços'
    } else {
      return ''
    }
  }

  const filterMedia = name => {
    const tempMedia = evaluation.find(item => item.evaluated_by === name)

    if (tempMedia) {
      return tempMedia.media.toFixed(2)
    } else {
      return '---'
    }
  }

  const filterAnswerUuid = questionUuid => {
    const tempData = respForm.find(e => e.question_uuid === questionUuid)

    return tempData ? tempData.uuid : ''
  }

  useEffect(() => {
    loadSpecialist()
    loadData()
  }, [])

  return (
    <Box>
      {uniqueId !== null && (
        <Box sx={{ display: 'flex', gap: 1, maxWidth: '100px' }}>
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            size="large"
            type="submit"
            variant="contained"
            onClick={() => setUniqueId(null)}
          >
            Voltar
          </Button>
        </Box>
      )}
      {uniqueId !== null ? (
        formExib ? (
          questions.length > 0 && (
            <Box sx={styles.Box}>
              <Box
                sx={{
                  maxWidth: {
                    xs: 320,
                    sm: '100vw',
                    md: '100vw',
                    lg: '100vw',
                    xl: '100vw'
                  }
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Auto Diagnóstico" {...a11yProps(0)} />
                  <Tab label="Conhecimento do Mercado" {...a11yProps(1)} />
                  <Tab label="Liderança Transformadora e Intenção de Inovar" {...a11yProps(2)} />
                  <Tab label="Gestão de Projetos e Pessoas para inovação" {...a11yProps(3)} />
                  <Tab label="Gestão estratégica de tecnologias e organicidade" {...a11yProps(4)} />
                  <Tab label="Avaliação de desempenho e resultados de inovação" {...a11yProps(5)} />
                  {/* <Tab label="Melhoria" {...a11yProps(6)} /> */}
                </Tabs>
              </Box>

              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <>
                    <QuestionReview
                      title={
                        filterQuestions(questions, 'Auto Diagnóstico')
                          .group.replace(/^\d\. /, '') || ''
                      }
                      value={
                        filterQuestions(questions, 'Auto Diagnóstico')
                          .statement || ''
                      }
                      type="text"
                    />
                    <br />
                    {filterQuestions(
                      questions,
                      'Auto Diagnóstico'
                    ).data.map((q, index) => (
                      <>
                        <QuestionReview
                          key={index}
                          title={q.statement}
                          options={q.options}
                          uuid={q.uuid}
                          type="radio-zero"
                          disabled={true}
                          resp={filterResp(q.uuid)}
                          setResp={() => {}}
                          typeButtonFile={true}
                          evidence={filterEvidence(q.uuid)}
                          updateEvidence={handlePdfView}
                          answerUuid={filterAnswerUuid(q.uuid)}
                          avaliationValue={filterEvaluations(
                            filterAnswerUuid(q.uuid)
                          )}
                          onChangeAvaliationValue={() => {}}
                        />
                        <br />
                      </>
                    ))}
                  </>
                </TabPanel>

                <TabPanel value={value} index={1} dir={theme.direction}>
                  <>
                    <QuestionReview
                      title={
                        filterQuestions(questions, 'Conhecimento do Mercado').group || ''
                      }
                      value={
                        filterQuestions(questions, 'Conhecimento do Mercado').statement || ''
                      }
                      type="text"
                    />
                    <br />
                    {filterQuestions(questions, 'Conhecimento do Mercado').data.map(
                      (q, index) => (
                        <>
                          <QuestionReview
                            key={index}
                            title={q.statement}
                            options={q.options}
                            uuid={q.uuid}
                            type="radio"
                            disabled={true}
                            resp={filterResp(q.uuid)}
                            setResp={() => {}}
                            typeButtonFile={true}
                            evidence={filterEvidence(q.uuid)}
                            updateEvidence={handlePdfView}
                            answerUuid={filterAnswerUuid(q.uuid)}
                            avaliationValue={filterEvaluations(
                              filterAnswerUuid(q.uuid)
                            )}
                            onChangeAvaliationValue={() => {}}
                          />
                          <br />
                        </>
                      )
                    )}
                  </>
                </TabPanel>

                <TabPanel value={value} index={2} dir={theme.direction}>
                  <>
                    <QuestionReview
                      title={
                        filterQuestions(questions, 'Liderança Transformadora e Intenção de Inovar').group || ''
                      }
                      value={
                        filterQuestions(questions, 'Liderança Transformadora e Intenção de Inovar').statement ||
                        ''
                      }
                      type="text"
                    />
                    <br />
                    {filterQuestions(questions, 'Liderança Transformadora e Intenção de Inovar').data.map(
                      (q, index) => (
                        <>
                          <QuestionReview
                            key={index}
                            title={q.statement}
                            options={q.options}
                            uuid={q.uuid}
                            type="radio"
                            disabled={true}
                            resp={filterResp(q.uuid)}
                            setResp={() => {}}
                            typeButtonFile={true}
                            evidence={filterEvidence(q.uuid)}
                            updateEvidence={handlePdfView}
                            answerUuid={filterAnswerUuid(q.uuid)}
                            avaliationValue={filterEvaluations(
                              filterAnswerUuid(q.uuid)
                            )}
                            onChangeAvaliationValue={() => {}}
                          />
                          <br />
                        </>
                      )
                    )}
                  </>
                </TabPanel>

                <TabPanel value={value} index={3} dir={theme.direction}>
                  <>
                    <QuestionReview
                      title={filterQuestions(questions, 'Gestão de Projetos e Pessoas para inovação').group || ''}
                      value={
                        filterQuestions(questions, 'Gestão de Projetos e Pessoas para inovação').statement || ''
                      }
                      type="text"
                    />
                    <br />
                    {filterQuestions(questions, 'Gestão de Projetos e Pessoas para inovação').data.map(
                      (q, index) => (
                        <>
                          <QuestionReview
                            key={index}
                            title={q.statement}
                            options={q.options}
                            uuid={q.uuid}
                            type="radio"
                            disabled={true}
                            resp={filterResp(q.uuid)}
                            setResp={() => {}}
                            typeButtonFile={true}
                            evidence={filterEvidence(q.uuid)}
                            updateEvidence={handlePdfView}
                            answerUuid={filterAnswerUuid(q.uuid)}
                            avaliationValue={filterEvaluations(
                              filterAnswerUuid(q.uuid)
                            )}
                            onChangeAvaliationValue={() => {}}
                          />
                          <br />
                        </>
                      )
                    )}
                  </>
                </TabPanel>

                <TabPanel value={value} index={4} dir={theme.direction}>
                  <>
                    <QuestionReview
                      title={filterQuestions(questions, 'Gestão estratégica de tecnologias e organicidade').group || ''}
                      value={
                        filterQuestions(questions, 'Gestão estratégica de tecnologias e organicidade').statement || ''
                      }
                      type="text"
                    />
                    <br />
                    {filterQuestions(questions, 'Gestão estratégica de tecnologias e organicidade').data.map(
                      (q, index) => (
                        <>
                          <QuestionReview
                            key={index}
                            title={q.statement}
                            options={q.options}
                            uuid={q.uuid}
                            type="radio"
                            disabled={true}
                            resp={filterResp(q.uuid)}
                            setResp={() => {}}
                            typeButtonFile={true}
                            evidence={filterEvidence(q.uuid)}
                            updateEvidence={handlePdfView}
                            answerUuid={filterAnswerUuid(q.uuid)}
                            avaliationValue={filterEvaluations(
                              filterAnswerUuid(q.uuid)
                            )}
                            onChangeAvaliationValue={() => {}}
                          />
                          <br />
                        </>
                      )
                    )}
                  </>
                </TabPanel>

                <TabPanel value={value} index={5} dir={theme.direction}>
                  <>
                    <QuestionReview
                      title={
                        filterQuestions(questions, 'Avaliação de desempenho e resultados de inovação')
                          .group || ''
                      }
                      value={
                        filterQuestions(questions, 'Avaliação de desempenho e resultados de inovação')
                          .statement || ''
                      }
                      type="text"
                    />
                    <br />
                    {filterQuestions(
                      questions,
                      'Avaliação de desempenho e resultados de inovação'
                    ).data.map((q, index) => (
                      <>
                        <QuestionReview
                          key={index}
                          title={q.statement}
                          options={q.options}
                          uuid={q.uuid}
                          type="radio"
                          disabled={true}
                          resp={filterResp(q.uuid)}
                          setResp={() => {}}
                          typeButtonFile={true}
                          evidence={filterEvidence(q.uuid)}
                          updateEvidence={handlePdfView}
                          answerUuid={filterAnswerUuid(q.uuid)}
                          avaliationValue={filterEvaluations(
                            filterAnswerUuid(q.uuid)
                          )}
                          onChangeAvaliationValue={() => {}}
                        />
                        <br />
                      </>
                    ))}
                  </>
                </TabPanel>

                {/* <TabPanel value={value} index={6} dir={theme.direction}>
                  <>
                    <QuestionReview
                      title={filterQuestions(questions, 'Melhoria').group || ''}
                      value={
                        filterQuestions(questions, 'Melhoria').statement || ''
                      }
                      type="text"
                    />
                    <br />
                    {filterQuestions(questions, 'Melhoria').data.map(
                      (q, index) => (
                        <>
                          <QuestionReview
                            key={index}
                            title={q.statement}
                            options={q.options}
                            uuid={q.uuid}
                            type="radio"
                            disabled={true}
                            resp={filterResp(q.uuid)}
                            setResp={() => {}}
                            typeButtonFile={true}
                            evidence={filterEvidence(q.uuid)}
                            updateEvidence={handlePdfView}
                            answerUuid={filterAnswerUuid(q.uuid)}
                            avaliationValue={filterEvaluations(
                              filterAnswerUuid(q.uuid)
                            )}
                            onChangeAvaliationValue={() => {}}
                          />
                          <br />
                        </>
                      )
                    )}
                  </>
                </TabPanel> */}
              </SwipeableViews>
            </Box>
          )
        ) : (
          <>
            <Typography variant="h5" sx={{ marginTop: 2 }}>
              Empresa
            </Typography>
            <Box sx={styles.Box}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 1
                }}
              >
                <Box sx={styles.TextExibition}>
                  <Typography sx={styles.LabelExibition}>
                    Nome Fantasia:
                  </Typography>
                  <Typography sx={styles.IExib}>{user.trade_name}</Typography>
                </Box>
                <Box sx={styles.TextExibition}>
                  <Typography sx={styles.LabelExibition}>
                    Razão Social:
                  </Typography>
                  <Typography sx={styles.IExib}>{user.legal_name}</Typography>
                </Box>
                <Box sx={styles.TextExibition}>
                  <Typography sx={styles.LabelExibition}>CNPJ:</Typography>
                  <Typography sx={styles.IExib}>{user.cnpj}</Typography>
                </Box>
                <Box sx={styles.TextExibition}>
                  <Typography sx={styles.LabelExibition}>Porte:</Typography>
                  <Typography sx={styles.IExib}>{user.size}</Typography>
                </Box>
                <Box sx={styles.TextExibition}>
                  <Typography sx={styles.LabelExibition}>Endereço:</Typography>
                  <Typography sx={styles.IExib}>{user.address}</Typography>
                </Box>
                <Box sx={styles.TextExibition}>
                  <Typography sx={styles.LabelExibition}>Estado:</Typography>
                  <Typography sx={styles.IExib}>
                    {user?.state ? user.state.toUpperCase() : ''}
                  </Typography>
                </Box>
                <Box sx={styles.TextExibition}>
                  <Typography sx={styles.LabelExibition}>Cidade:</Typography>
                  <Typography sx={styles.IExib}>{user.city}</Typography>
                </Box>
                <Box sx={styles.TextExibition}>
                  <Typography sx={styles.LabelExibition}>
                    Cidade Matriz:
                  </Typography>
                  <Typography sx={styles.IExib}>
                    {user.head_office_city}
                  </Typography>
                </Box>
                <Box sx={styles.TextExibition}>
                  <Typography sx={styles.LabelExibition}>
                    Categoria de Negócio:
                  </Typography>
                  <Typography sx={styles.IExib}>
                    {filterBusiness(user.business_category)}
                  </Typography>
                </Box>
                <Box sx={styles.TextExibition}>
                  <Typography sx={styles.LabelExibition}>
                    Nome do Responsável:
                  </Typography>
                  <Typography sx={styles.IExib}>
                    {user.full_name_responsible}
                  </Typography>
                </Box>
                <Box sx={styles.TextExibition}>
                  <Typography sx={styles.LabelExibition}>
                    Email do Responsável:
                  </Typography>
                  <Typography sx={styles.IExib}>
                    {user.email_responsible}
                  </Typography>
                </Box>
                <Box sx={styles.TextExibition}>
                  <Typography sx={styles.LabelExibition}>
                    Telefone do Responsável:
                  </Typography>
                  <Typography sx={styles.IExib}>{user.phone}</Typography>
                </Box>
              </Box>
            </Box>
            <Typography variant="h5" sx={{ marginTop: 1 }}>
              Avaliadores
            </Typography>
            <Box sx={styles.Box}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 1
                }}
              >
                {filteredSpecialists.map(user => (
                  <>
                    <Box sx={styles.TextExibition}>
                      <Typography sx={styles.LabelExibition}>
                        Avaliador:
                      </Typography>
                      <Typography sx={styles.IExib}>{user.name}</Typography>
                    </Box>
                    <Box sx={styles.Nota}>
                      <Box sx={styles.TextExibition}>
                        <Typography sx={styles.LabelExibition}>Nota</Typography>
                        <Typography sx={styles.IExib}>
                          {filterMedia(user.name)}
                        </Typography>
                      </Box>
                      <Button
                        sx={styles.ButtonNota}
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={() => {
                          setSelectedSpecialist(user.uuid)
                          setFormExib(true)
                        }}
                      >
                        Visualizar Avaliação
                      </Button>
                    </Box>
                  </>
                ))}
              </Box>
            </Box>
          </>
        )
      ) : (
        <Table rows={rows} columns={columns} handleSubmit={handleSubmit} />
      )}

      {formExib && (
        <Box sx={{ display: 'flex', gap: 1 }}>
          {value !== 0 ? (
            <Button
              fullWidth
              sx={{ marginTop: 1 }}
              size="large"
              type="submit"
              variant="contained"
              onClick={() => moveAnt()}
            >
              Anterior
            </Button>
          ) : (
            <></>
          )}
          {value !== 6 && uniqueId !== null && (
            <Button
              fullWidth
              sx={{ marginTop: 1 }}
              size="large"
              type="submit"
              variant="contained"
              onClick={() => moveProx()}
            >
              Proximo
            </Button>
          )}
        </Box>
      )}

      <Alert
        open={openAlert}
        setOpen={setOpenAlert}
        alert={typeAlert}
        msg={msg}
        delete={handleDelete}
        success={() => {
          setOpenAlert(false)
          window.location.reload()
        }}
      />

      <PDFModal
        base64String={pdfString}
        isOpen={isOpenPdfModal}
        onClose={() => setIsOpenPdfModal(false)}
      />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.Modal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Atribuir este Formulário
          </Typography>
          <Typography id="modal-modal-description">
            Selecione os Avaliadores que serão responsáveis por este formulário.
          </Typography>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-multiple-name-label">Name</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              sx={{ width: '100%' }}
              value={personName}
              onChange={e => {
                setPersonName(e.target.value)
              }}
              input={<OutlinedInput label="Name" />}
              MenuProps={MenuProps}
            >
              {names.map(name => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            size="large"
            type="submit"
            variant="contained"
            onClick={() => {}}
          >
            Atribuir
          </Button>
        </Box>
      </Modal>
    </Box>
  )
}

export default index
