import axios from 'axios'

import * as validators from './validators'

export const changeForm = (array, setArray, name, newValue) => {
  setArray({
    ...array,
    [name]: newValue
  })
}

export const showData = data => {
  const aux = []
  for (let i = 0; i < data.length; i++) {
    aux.push({
      ...data[i],
      type: 'TextShow',
      measures: {
        xs: 12,
        sm: 12
      }
    })
  }
  return aux
}

export const validateForm = (form, value) => {
  let temp = false
  for (let i = 0; i < form.length; i++) {
    if (value[form[i].name]) {
      temp = validators[form[i].validator](value[form[i].name]).err
      if (temp === true) break
    } else {
      temp = validators[form[i].validator]('').err
      if (temp === true) break
    }
  }
  return temp
}

export const msgError = error => {
  if (axios.isAxiosError(error)) {
    // Filtrando o erro para verificar se é um AxiosError
    const axiosError = error

    if (axiosError.response) {
      // O erro possui uma resposta do servidor
      return { message: axiosError.response.data }
    } else if (axiosError.request) {
      // A requisição foi feita, mas não houve resposta do servidor
      return { message: 'Sem resposta do servidor!' }
    } else {
      // Outros tipos de erro
      return { message: axiosError.message }
    }
  } else {
    // Outros tipos de erro que não são AxiosError
    return { message: 'Ocorreu um erro!' }
  }
}
