import { colors } from '../../core/stylesConfig'

export const Box = {
  backgroundColor: colors.common.white,
  borderRadius: 1,
  boxShadow: colors.shadow.min,
  padding: 2,
  margin: '6px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: 2
}

export const Modal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: 4
}

export const TextExibition = {
  width: '49%',
  margin: '2px 0',
  borderRadius: 1,
  border: '1px solid #c1c1c1',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap'
}

export const LabelExibition = {
  color: '#666666',
  fontSize: '12px',
  bgcolor: '#FFF',
  margin: '-8px 6px 0px 6px',
  padding: '0 3px',
  width: 'fit-content'
}

export const IExib = {
  fontSize: '16px',
  padding: '0 8px 8px 8px',
  width: '100%'
}

export const Nota = {
  width: '49%',
  display: 'flex',
  justifyContent: 'space-between'
}

export const ButtonNota = {
  width: '49%',
  margin: '2px 0'
}

export const LatestNote = {
  width: '200px',
  margin: '2px 0',
  borderRadius: 1,
  border: '1px solid #c1c1c1',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap'
}
