import { colors } from '../../core/stylesConfig'

export const Box = {
  backgroundColor: colors.common.white,
  borderRadius: 1,
  boxShadow: colors.shadow.min,
  padding: 2,
  margin: '6px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: 2
}
