import * as Components from '../../components'

import { admList, menuList, opList, specialist } from './data'

const Home = () => {
  return (
    <>
      <Components.Base
        list={menuList}
        list2={opList}
        list3={admList}
        list4={specialist}
      />
    </>
  )
}

export default Home
