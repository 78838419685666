export const Dialog = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'space-evenly',
  maxHeight: '400px',
  height: '80vh',
  maxWidth: '400px',
  width: '80vw',
  padding: '25px'
}
