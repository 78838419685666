import { uniqueId } from 'lodash'

export const dataForm = [
  {
    key: uniqueId(),
    label: 'Email',
    name: 'username',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Password',
    name: 'password',
    type: 'Password',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 12
    }
  }
]
