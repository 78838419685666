export const colors = {
  common: {
    black: '#000000',
    white: '#ffffff'
  },
  primary: {
    main: '#29166f',
    light: '#f7c200',
    dark: '#6443e0',
    green: '#00913f',
    contrastText: '#ffffff',
    text: '#000000'
  },
  button: {
    primary: '#616161',
    primaryHover: '#424242'
  },
  shadow: {
    min: '0px 0px 10px #00000011',
    bold: '4px 4px 5px #00000044'
  },
  // secondary: {
  //   main: amber[700],
  //   light: amber[400],
  //   dark: amber[800],
  //   contrastText: '#ffffff'
  // },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#f5f5f5',
    A200: '#efefef',
    A400: '#bdbdbd',
    A700: '#616161'
  },
  background: {
    default: '#E8E8E8',
    variant: '#ffffff',
    logo: '#008000'
  }
}
