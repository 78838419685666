import React, { useEffect } from 'react'
import SwipeableViews from 'react-swipeable-views'

// import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import api from '../../services/api'
import Alert from '../../components/Alert'
import Question from '../../components/Question'
import BoasVindas from '../../components/BoasVindas'

import * as styles from './styles'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../routes/data'

const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const restructureData = inputData => {
  const output = []
  // Filtrar os principais títulos
  const mainTitles = inputData.filter(item => item.options.length === 0)

  mainTitles.forEach(title => {
    const sectionNumber = title.group.split('.')[0] // Pega o número do título principal

    const relatedData = inputData.filter(
      item => item.group.startsWith(sectionNumber) && item.options.length !== 0
    )

    output.push({
      uuid: title.uuid,
      group: title.group.replace(/^\d+\.\d+ /, ''), // Remove números do título
      statement: title.statement,
      data: relatedData
    })
  })

  return output
}

const a11yProps = index => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const index = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [permissao, setPermissao] = React.useState(false)
  const [value, setValue] = React.useState(0)
  const [questions, setQuestions] = React.useState([])

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)
  const [msg, setMsg] = React.useState('')
  const [respForm, setRespForm] = React.useState([])
  const [tempLangForm, setTempLargForm] = React.useState(0)

  const [control, setControl] = React.useState(false)

  const handleChange = (_event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  const filterQuestions = (list, label) => {
    const temp = list.find(e =>
      e.group.toLowerCase().includes(label.toLowerCase())
    )

    return temp
  }

  const filterResp = questionUuid => {
    const tempData = respForm.find(e => e.question_uuid === questionUuid)

    return tempData ? tempData.option_uuid : ''
  }

  const filterEvidence = questionUuid => {
    const tempData = respForm.find(e => e.question_uuid === questionUuid)

    if (tempData && tempData?.evidence && tempData.evidence !== null) {
      return tempData?.evidence
    } else {
      return null
    }
  }

  const updateOrAddObject = (optionUuid, questionUuid, list) => {
    const tempData = list.find(e => e.uuid === optionUuid)

    const newObject = {
      option_weight: tempData.weight,
      question_uuid: questionUuid,
      option_uuid: optionUuid
    }

    setRespForm(prevRespForm => {
      const existingIndex = prevRespForm.findIndex(
        item => item.question_uuid === questionUuid
      )

      if (existingIndex !== -1) {
        return [
          ...prevRespForm.slice(0, existingIndex),
          newObject,
          ...prevRespForm.slice(existingIndex + 1)
        ]
      } else {
        return [...prevRespForm, newObject]
      }
    })
  }

  const updateEvidence = (evidence, questionUuid) => {
    if (evidence && evidence.type === 'application/pdf') {
      const reader = new FileReader()
      reader.onload = e => {
        const base64String = e.target.result.split(',')[1]

        const existingItemIndex = respForm.findIndex(
          item => item.question_uuid === questionUuid
        )

        let newObject

        if (existingItemIndex !== -1) {
          newObject = {
            ...respForm[existingItemIndex],
            evidence: base64String
          }
        } else {
          newObject = {
            evidence: base64String,
            option_weight: null,
            question_uuid: questionUuid,
            option_uuid: null
          }
        }

        setRespForm(prevRespForm => {
          const existingIndex = prevRespForm.findIndex(
            item => item.question_uuid === questionUuid
          )

          if (existingIndex !== -1) {
            return [
              ...prevRespForm.slice(0, existingIndex),
              newObject,
              ...prevRespForm.slice(existingIndex + 1)
            ]
          } else {
            return [...prevRespForm, newObject]
          }
        })
      }

      reader.readAsDataURL(evidence)
    } else {
      setMsg('Por favor, selecione um arquivo PDF.')
      setTypeAlert('error')
      setOpenAlert(true)
    }
  }

  const loadData = async () => {
    setTypeAlert('loading')
    setOpenAlert(true)

    try {
      const resp = await api.get('/question/', { params: { all: true } })
      const resp2 = resp.data.filter((item) => item.type !== "intro")
      setTempLargForm(resp2.length)
      if (resp) {
        const temp = restructureData(resp.data)
        setQuestions(temp)
        setOpenAlert(false)
      }
    } catch (err) {
      setMsg('Não foi possível adquirir as Questões!')
      setTypeAlert('error')
    }
  }

  const validateArray = array => {
    const selfDiagnosis = questions.find(
      e => e.group.toLowerCase().includes('Auto Diagnóstico'.toLowerCase())
    )

    if(selfDiagnosis) {
      for (const obj of array) {
        const tempQuestionUUID = selfDiagnosis.data.find(
          e => e.uuid === obj.question_uuid
        )
        
        if (!tempQuestionUUID) {     
          if (obj.option_weight >= 3) {
            if (obj.evidence === undefined || obj.evidence === null) {
              return false
            }
          }
        }
      }
    }


    return true
  }

  const handleSubmit = async () => {
    setMsg('')
    if (respForm.length === tempLangForm) {
      if (validateArray(respForm) === true) {
        try {
          setTypeAlert('loading')
          setOpenAlert(true)
          await api.post('/answer', respForm)

          setMsg('Seu formulário foi submetido com sucesso!')
          setTypeAlert('success')
          setTimeout(() => navigate(routes.formfull), 2000)
        } catch (err) {
          if(err.response?.data?.detail?.[0]?.msg === 'O arquivo PDF excede o limite de 10 MB.') {
            setMsg('O arquivo PDF excede o limite de 10 MB.')
          } else {
            setMsg('Não foi possível enviar as Questões!')
          }          
          setTypeAlert('error')
          setOpenAlert(true)
          console.log(err)
        }
      } else {
        setMsg('Existe questões sem evidências!')
        setTypeAlert('error')
        setOpenAlert(true)
      }
    } else {
      setMsg('Existe questões não preenchidas!')
      setTypeAlert('error')
      setOpenAlert(true)
    }
  }

  const moveProx = () => {
    setValue(value + 1)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  const moveAnt = () => {
    setValue(value - 1)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <Box>
      {permissao ? (
        <Box sx={styles.Box}>
          <Box
            sx={{
              maxWidth: {
                xs: 320,
                sm: '100vw',
                md: '100vw',
                lg: '100vw',
                xl: '100vw'
              }
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Auto Diagnóstico" {...a11yProps(0)} />
              <Tab label="Conhecimento do Mercado" {...a11yProps(1)} />
              <Tab label="Liderança Transformadora e Intenção de Inovar" {...a11yProps(2)} />
              <Tab label="Gestão de Projetos e Pessoas para inovação" {...a11yProps(3)} />
              <Tab label="Gestão estratégica de tecnologias e organicidade" {...a11yProps(4)} />
              <Tab label="Avaliação de desempenho e resultados de inovação" {...a11yProps(5)} />
              {/* <Tab label="Melhoria" {...a11yProps(6)} /> */}
            </Tabs>
          </Box>

          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <>
                <Question
                  title={
                    filterQuestions(questions, 'Auto Diagnóstico')
                      .group.replace(/^\d\. /, '') || ''
                  }
                  value={
                    filterQuestions(questions, 'Auto Diagnóstico')
                      .statement || ''
                  }
                  type="text"
                />
                <br />
                {filterQuestions(questions, 'Auto Diagnóstico').data.sort((a, b) => {
                  const numA = a.statement.split(' ')[0]
                  const numB = b.statement.split(' ')[0]

                  const partesA = numA.split('.').map(Number)
                  const partesB = numB.split('.').map(Number)

                  for (let i = 0; i < Math.max(partesA.length, partesB.length); i++) {
                    if (partesA[i] !== partesB[i]) {
                      return (partesA[i] || 0) - (partesB[i] || 0)
                    }
                  }
                  return 0
                }).map(
                  (q, index) => (
                    <>
                      <Question
                        key={index}
                        title={q.statement}
                        options={q.options}
                        uuid={q.uuid}
                        type="radio-zero"
                        resp={filterResp(q.uuid)}
                        setResp={updateOrAddObject}
                        evidence={filterEvidence(q.uuid)}
                        updateEvidence={updateEvidence}
                      />
                      <br />
                    </>
                  )
                )}
              </>
            </TabPanel>

            <TabPanel value={value} index={1} dir={theme.direction}>
              <>
                <Question
                  title={
                    filterQuestions(questions, 'Conhecimento do Mercado')
                      .group || ''
                  }
                  value={
                    filterQuestions(questions, 'Conhecimento do Mercado')
                      .statement || ''
                  }
                  type="text"
                />
                <br />
                {filterQuestions(questions, 'Conhecimento do Mercado').data.map(
                  (q, index) => (
                    <>
                      <Question
                        key={index}
                        title={q.statement}
                        options={q.options}
                        uuid={q.uuid}
                        type="radio"
                        resp={filterResp(q.uuid)}
                        setResp={updateOrAddObject}
                        evidence={filterEvidence(q.uuid)}
                        updateEvidence={updateEvidence}
                      />
                      <br />
                    </>
                  )
                )}
              </>
            </TabPanel>

            <TabPanel value={value} index={2} dir={theme.direction}>
              <>
                <Question
                  title={filterQuestions(questions, 'Liderança Transformadora e Intenção de Inovar').group || ''}
                  value={
                    filterQuestions(questions, 'Liderança Transformadora e Intenção de Inovar').statement || ''
                  }
                  type="text"
                />
                <br />
                {filterQuestions(questions, 'Liderança Transformadora e Intenção de Inovar').data.map(
                  (q, index) => (
                    <>
                      <Question
                        key={index}
                        title={q.statement}
                        options={q.options}
                        uuid={q.uuid}
                        type="radio"
                        resp={filterResp(q.uuid)}
                        setResp={updateOrAddObject}
                        evidence={filterEvidence(q.uuid)}
                        updateEvidence={updateEvidence}
                      />
                      <br />
                    </>
                  )
                )}
              </>
            </TabPanel>

            <TabPanel value={value} index={3} dir={theme.direction}>
              <>
                <Question
                  title={filterQuestions(questions, 'Gestão de Projetos e Pessoas para inovação').group || ''}
                  value={
                    filterQuestions(questions, 'Gestão de Projetos e Pessoas para inovação').statement || ''
                  }
                  type="text"
                />
                <br />
                {filterQuestions(questions, 'Gestão de Projetos e Pessoas para inovação').data.map(
                  (q, index) => (
                    <>
                      <Question
                        key={index}
                        title={q.statement}
                        options={q.options}
                        uuid={q.uuid}
                        type="radio"
                        resp={filterResp(q.uuid)}
                        setResp={updateOrAddObject}
                        evidence={filterEvidence(q.uuid)}
                        updateEvidence={updateEvidence}
                      />
                      <br />
                    </>
                  )
                )}
              </>
            </TabPanel>

            <TabPanel value={value} index={4} dir={theme.direction}>
              <>
                <Question
                  title={filterQuestions(questions, 'Gestão estratégica de tecnologias e organicidade').group || ''}
                  value={filterQuestions(questions, 'Gestão estratégica de tecnologias e organicidade').statement || ''}
                  type="text"
                />
                <br />
                {filterQuestions(questions, 'Gestão estratégica de tecnologias e organicidade').data.map((q, index) => (
                  <>
                    <Question
                      key={index}
                      title={q.statement}
                      options={q.options}
                      uuid={q.uuid}
                      type="radio"
                      resp={filterResp(q.uuid)}
                      setResp={updateOrAddObject}
                      evidence={filterEvidence(q.uuid)}
                      updateEvidence={updateEvidence}
                    />
                    <br />
                  </>
                ))}
              </>
            </TabPanel>

            <TabPanel value={value} index={5} dir={theme.direction}>
              <>
                <Question
                  title={filterQuestions(questions, 'Avaliação de desempenho e resultados de inovação').group || ''}
                  value={filterQuestions(questions, 'Avaliação de desempenho e resultados de inovação').statement || ''}
                  type="text"
                />
                <br />
                {filterQuestions(questions, 'Avaliação de desempenho e resultados de inovação').data.map((q, index) => (
                  <>
                    <Question
                      key={index}
                      title={q.statement}
                      options={q.options}
                      uuid={q.uuid}
                      type="radio"
                      resp={filterResp(q.uuid)}
                      setResp={updateOrAddObject}
                      evidence={filterEvidence(q.uuid)}
                      updateEvidence={updateEvidence}
                    />
                    <br />
                  </>
                ))}
              </>
            </TabPanel>

            {/* <TabPanel value={value} index={6} dir={theme.direction}>
              <>
                <Question
                  title={filterQuestions(questions, 'Melhoria').group || ''}
                  value={filterQuestions(questions, 'Melhoria').statement || ''}
                  type="text"
                />
                <br />
                {filterQuestions(questions, 'Melhoria').data.map((q, index) => (
                  <>
                    <Question
                      key={index}
                      title={q.statement}
                      options={q.options}
                      uuid={q.uuid}
                      type="radio"
                      resp={filterResp(q.uuid)}
                      setResp={updateOrAddObject}
                      evidence={filterEvidence(q.uuid)}
                      updateEvidence={updateEvidence}
                    />
                    <br />
                  </>
                ))}
              </>
            </TabPanel> */}
          </SwipeableViews>
        </Box>
      ) : (
        <BoasVindas setControl={setControl}/>
      )}
      {permissao ? (
        <Box sx={{ display: 'flex', gap: 1 }}>
          {value !== 0 ? (
            <Button
              fullWidth
              sx={{ marginTop: 1 }}
              size="large"
              type="submit"
              variant="contained"
              onClick={() => moveAnt()}
            >
              Anterior
            </Button>
          ) : (
            <></>
          )}
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            size="large"
            type="submit"
            variant="contained"
            onClick={() => {
              value !== 5 ? moveProx() : setOpenModal(true)
            }}
          >
            {value !== 5 ? 'Próximo' : 'Enviar'}
          </Button>
        </Box>
      ) : ( 
        control === true && (
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            size="large"
            type="submit"
            variant="contained"
            onClick={() => setPermissao(!permissao)}
          >
            Permitir
          </Button>
        )
      )}

      <Alert
        open={openAlert}
        setOpen={setOpenAlert}
        alert={typeAlert}
        msg={msg}
        delete={() => {}}
        success={() => {
          setOpenAlert(false)
          window.location.reload()
        }}
      />
      
      <Dialog open={openModal} aria-labelledby="responsive-dialog-title">
        <Box sx={styles.Dialog}>
          <Typography variant="h6">Após submetido, não poderá alterar suas respostas ou incluir novos documentos, realmente deseja proseguir?</Typography>
          <Button variant="contained" onClick={() => {
            setOpenModal(false)
            handleSubmit()
          }}>
            Confirmar
          </Button>
        </Box>
      </Dialog>
    </Box>
  )
}

export default index
