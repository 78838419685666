import { uniqueId } from 'lodash'

export const dataForm = [
  {
    key: uniqueId(),
    label: 'Nome Fantasia',
    name: 'trade_name',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Razão Social',
    name: 'legal_name',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 12
    }
  },
  {
    key: uniqueId(),
    label: 'CNPJ',
    name: 'cnpj',
    type: 'TextField',
    mask: 'Cnpj',
    validator: 'Cnpj',
    measures: {
      xs: 12,
      md: 6
    }
  },
  {
    key: uniqueId(),
    label: 'Porte',
    name: 'size',
    type: 'Select',
    mask: 'Text',
    validator: 'Required',
    data: [
      {
        uuid: 'Micro: Até R$ 360 mil',
        name: 'Micro: Até R$ 360 mil'
      },
      {
        uuid: 'Pequena: Até R$ 4,8 milhões',
        name: 'Pequena: Até R$ 4,8 milhões'
      },
      {
        uuid: 'Media: Até R$ 300 milhões',
        name: 'Media: Até R$ 300 milhões'
      }
    ],
    measures: {
      xs: 12,
      md: 6
    }
  },
  {
    key: uniqueId(),
    label: 'Bairro',
    name: 'district',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 4
    }
  },
  {
    key: uniqueId(),
    label: 'Rua',
    name: 'street',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 4
    }
  },
  {
    key: uniqueId(),
    label: 'Número',
    name: 'number',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 4
    }
  },
  // {
  //   key: uniqueId(),
  //   label: 'Cidade',
  //   name: 'city',
  //   type: 'TextField',
  //   mask: 'Text',
  //   validator: 'Required',
  //   measures: {
  //     xs: 12,
  //     md: 6
  //   }
  // },
  // {
  //   key: uniqueId(),
  //   label: 'Estado',
  //   name: 'state',
  //   type: 'TextField',
  //   mask: 'Text',
  //   validator: 'Required',
  //   measures: {
  //     xs: 12,
  //     md: 6
  //   }
  // },dependency
  {
    key: uniqueId(),
    label: 'Estado',
    name: 'state',
    type: 'Dependency',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 6
    }
  },
  {
    key: uniqueId(),
    label: 'Cidade Matriz',
    name: 'head_office_city',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 6
    }
  },
  {
    key: uniqueId(),
    label: 'Categoria de Negócio',
    name: 'business_category',
    type: 'Select',
    mask: 'Text',
    validator: 'Required',
    data: [
      {
        uuid: '1',
        name: 'Indústria'
      },
      {
        uuid: '2',
        name: 'Comércio'
      },
      {
        uuid: '3',
        name: 'Serviços'
      }
    ],
    measures: {
      xs: 12,
      md: 6
    }
  },
  {
    key: uniqueId(),
    label: 'Nome do Responsável',
    name: 'full_name_responsible',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Email do Responsável',
    name: 'email_responsible',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 6
    }
  },
  {
    key: uniqueId(),
    label: 'CPF do Responsável',
    name: 'cpf',
    type: 'TextField',
    mask: 'Cpf',
    validator: 'Cpf',
    measures: {
      xs: 12,
      md: 6
    }
  },
  {
    key: uniqueId(),
    label: 'Cargo do Responsável',
    name: 'role_responsible',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 6
    }
  },
  {
    key: uniqueId(),
    label: 'Telefone do Responsável',
    name: 'phone',
    type: 'TextField',
    mask: 'Phone',
    validator: 'Phone',
    measures: {
      xs: 12,
      md: 6
    }
  },
  {
    key: uniqueId(),
    label: 'Senha',
    name: 'password',
    type: 'Password',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 6
    }
  },
  {
    key: uniqueId(),
    label: 'Repita a senha',
    name: 'password2',
    type: 'Password',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 6
    }
  }
]
