export const Group = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& .MuiFormControlLabel-root': {
    marginRight: 1,
    fontSize: '1rem'
  },
  '& .MuiButton-root': {
    width: 'auto',
    color: '#2222aa',
    textTransform: 'none',
    padding: 0,
    fontWeight: '400',
    fontSize: '1rem'
  }
}

export const Dialog = {
  '& .MuiPaper-root': {
    margin: 2,
    padding: 2,
    maxWidht: '600px',
    width: '100vw'
  }
}
