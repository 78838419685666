import { colors } from '../../core/stylesConfig'

export const Box = {
  backgroundColor: colors.common.white,
  borderRadius: 1,
  boxShadow: colors.shadow.min,
  padding: 2,
  margin: '6px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: 2
}

export const Dialog = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'space-evenly',
  maxHeight: '400px',
  height: '80vh',
  maxWidth: '400px',
  width: '80vw',
  padding: '25px'
}
