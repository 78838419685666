import React from 'react'
import Box from '@mui/material/Box'

import * as styles from './styles'
import Typography from '@mui/material/Typography'
import { Form, TableV2 } from '../../components'
import api from '../../services/api'
import Alert from '../../components/Alert'
import { Button, IconButton, Modal } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
// import EditIcon from '@mui/icons-material/Edit'
import { dataForm } from './data'
import { changeForm, validateForm } from '../../utils/functions'

const index = () => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '600px',
    width: '100%',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
    m: 2
  }

  const [rows, setRows] = React.useState([])

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const [open, setOpen] = React.useState(false)
  const [edit, setEdit] = React.useState(false)
  const [deleteUuid, setDeleteUuid] = React.useState('')
  const handleOpen = () => {
    setOpen(true)
    setFormData([])
    setEdit(false)
  }
  const handleClose = () => setOpen(false)

  const [isSubmit, setIsSubmit] = React.useState(false)
  const [formData, setFormData] = React.useState([])

  const handleChangeForm = (name, newValue) => {
    changeForm(formData, setFormData, name, newValue)
  }

  const formatCPF = n => {
    return `${n.slice(0, 3)}.${n.slice(3, 6)}.${n.slice(6, 9)}-${n.slice(
      9,
      11
    )}`
  }

  const columns = [
    {
      field: 'Ações',
      headerName: 'Ações',
      width: 100,
      renderCell: params => (
        <div>
          {/* <IconButton
            onClick={() => {
              setOpen(true)
              setEdit(true)
              setFormData(params.row)
            }}
          >
            <EditIcon size={24} color="#2f2fd3" />
          </IconButton> */}
          <IconButton
            title="Deletar"
            onClick={() => {
              setDeleteUuid(params.row.uuid)
              setMsg('Essa operação é irreverssível, deseja continuar?')
              setTypeAlert('delete')
              setOpenAlert(true)
            }}
          >
            <DeleteIcon size={24} color="#2f2fd3" />
          </IconButton>
        </div>
      )
    },
    { field: 'name', headerName: 'Nome', width: 200 },
    {
      field: 'cpf',
      headerName: 'CPF',
      width: 150,
      renderCell: params => {
        if (params.row.cpf) {
          return formatCPF(params.row.cpf)
        }
      }
    },
    { field: 'email', headerName: 'Email', width: 250 }
  ]

  const handleSubmit = async () => {
    setMsg('')
    setTypeAlert('loading')

    const validate = validateForm(dataForm, formData)
    if (validate === false) {
      setOpenAlert(true)

      const sendData = formData
      if (sendData.cpf) {
        sendData.cpf = sendData.cpf.replace(/[^0-9]/g, '').slice(0, 14)
      }

      try {
        const tempResp = await api.get('/role/', { params: { all: true } })

        const tempRole = tempResp.data.find(e => e.role_name === 'specialist')

        if (tempRole) {
          await api.post('/user/', {
            name: sendData.name,
            cpf: sendData.cpf,
            email: sendData.email,
            password: sendData.cpf,
            role_uuid: tempRole.uuid
          })

          setMsg('Cadastro realizado com sucesso!')
          setTypeAlert('success')
        } else {
          setMsg('Não foi possível realizar o cadastro!')
          setTypeAlert('error')
        }
      } catch (err) {
        if (err?.response?.data) {
          setMsg(err.response.data.detail[0].msg)
        } else {
          setMsg('Não foi possível realizar o cadastro!')
        }
        setTypeAlert('error')
      }
    } else {
      setIsSubmit(true)
    }
  }

  const handleDelete = async () => {
    try {
      setMsg('')
      setTypeAlert('loading')

      await api.delete('/user/', { params: { uuid: deleteUuid } })

      setMsg('Usuário excluido com sucesso!')
      setTypeAlert('success')
    } catch (err) {
      setMsg('Erro ao excluir o usuário, tente novamente!')
      setTypeAlert('error')
    }
  }

  const loadData = async () => {
    setMsg('')
    setTypeAlert('loading')
    setOpenAlert(true)

    try {
      const resp = await api.get('/role/', { params: { all: true } })
      const response = resp.data.find(item => item.role_name === 'specialist')
      const resp2 = await api.get('/user/', {
        params: { all: true, atributo: 'role_uuid', value: response.uuid }
      })
      if (resp2) {
        setRows(resp2.data)
        setOpenAlert(false)
      }
    } catch (err) {
      setMsg('Não foi possível adquirir os dados!')
      setTypeAlert('error')
    }
  }

  React.useEffect(() => {
    loadData()
  }, [])

  return (
    <Box>
      <Typography variant="h5">Lista de Avaliadores</Typography>

      <Box
        sx={{
          display: 'flex',
          gap: 1,
          width: '300px',
          position: 'absolute',
          top: '70px',
          right: '30px'
        }}
      >
        <Button
          fullWidth
          sx={{ marginTop: 1 }}
          size="large"
          type="submit"
          variant="contained"
          onClick={handleOpen}
        >
          Cadastrar Novo
        </Button>
      </Box>
      <Box sx={styles.Box}>
        <TableV2 rows={rows} columns={columns} handleSubmit={handleSubmit} />
      </Box>

      <Alert
        open={openAlert}
        setOpen={setOpenAlert}
        alert={typeAlert}
        msg={msg}
        delete={handleDelete}
        success={() => {
          setOpenAlert(false)
          setOpen(false)
          loadData()
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h5" sx={{ paddingBottom: 2 }}>
            {edit ? 'Editar' : 'Cadastrar'} Avaliador
          </Typography>
          <Form
            data={dataForm}
            isSubmit={isSubmit}
            value={formData}
            onChange={handleChangeForm}
          />
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSubmit}
          >
            {edit ? 'Editar' : 'Cadastrar'}
          </Button>
        </Box>
      </Modal>
    </Box>
  )
}

export default index
