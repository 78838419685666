export const routes = {
  login: process.env.PUBLIC_URL + '/',
  cadastrar: process.env.PUBLIC_URL + '/cadastrar',
  trocar_senha: process.env.PUBLIC_URL + '/trocar-senha',
  home: process.env.PUBLIC_URL + '/dashboard',
  formulario: process.env.PUBLIC_URL + '/dashboard/formulario',
  formfull: process.env.PUBLIC_URL + '/dashboard/minha-resposta',
  avaliar: process.env.PUBLIC_URL + '/dashboard/avaliar',
  avaliadas: process.env.PUBLIC_URL + '/dashboard/avaliadas',
  listar: process.env.PUBLIC_URL + '/dashboard/listar',
  listar_atribuidas: process.env.PUBLIC_URL + '/dashboard/listar-atribuidas',
  cadastrar_user: process.env.PUBLIC_URL + '/dashboard/lista-avaliadores',
  dashboard: process.env.PUBLIC_URL + '/dashboard/painel',
  ranking: process.env.PUBLIC_URL + '/dashboard/ranking'
}
