import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import { Modal } from '@mui/material'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const PDFModal = ({ base64String, isOpen, onClose }) => {
  const [numPages, setNumPages] = React.useState(null)

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div
        style={{
          width: '80%',
          height: '90%',
          margin: 'auto',
          overflow: 'auto',
          position: 'relative',
          backgroundColor: '#fff',
          padding: '16px',
          marginTop: '20px'
        }}
      >
        <IconButton
          style={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            zIndex: 1000,
            backgroundColor: 'rgba(255, 255, 255, 0.7)'
          }}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
        <Document
          file={{ data: atob(`${base64String}`) }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
    </Modal>
  )
}

export default PDFModal
