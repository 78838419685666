import { styled } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

import { colors } from '../../core/stylesConfig'

const drawerWidth = 280

export const Main = styled('main', {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  flexGrow: 1,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(10),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: `${drawerWidth}px`
  }),
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0px'
  }
}))

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  }),
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0px'
  }
}))

export const FooterBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  }),
  top: 'calc(100% - 64px)',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0px',
    top: 'calc(100% - 54px)'
  },
  backgroundColor: '#ffffff'
}))

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'center'
}))

export const Drawer = {
  width: drawerWidth,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '& .MuiDrawer-paper': {
    backgroundColor: colors.background.variant,
    width: drawerWidth,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxSizing: 'border-box'
  }
}

export const Logo = {
  display: 'flex',
  alignItems: 'center',
  padding: '5px'
}

export const ActivedButton = {
  bgcolor: colors.grey[50],
  color: colors.primary.text
}

export const InactivedButton = {
  color: colors.primary.text
}

export const Container = {
  display: 'flex',
  flexDirection: 'column',
  height: '100vh'
}

export const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  background: 'transparent',
  width: '100%',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

export const AccordionSummary = styled(props => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  background: 'transparent',
  padding: 0,
  margin: 0,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    margin: 0,
    alignItems: 'center'
  }
}))

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  width: '100%'
}))

export const NoPadding = {
  paddingTop: 0,
  paddingBottom: 0
}
