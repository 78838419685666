/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import SwipeableViews from 'react-swipeable-views'
import PDFModal from './PDFModal'

// import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import api from '../../services/api'
import Alert from '../../components/Alert'
import Question from '../../components/Question'

import * as styles from './styles'

const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const restructureData = inputData => {
  const output = []
  // Filtrar os principais títulos
  const mainTitles = inputData.filter(item => item.options.length === 0)

  mainTitles.forEach(title => {
    const sectionNumber = title.group.split('.')[0] // Pega o número do título principal

    const relatedData = inputData.filter(
      item => item.group.startsWith(sectionNumber) && item.options.length !== 0
    )

    output.push({
      group: title.group.replace(/^\d+\.\d+ /, ''), // Remove números do título
      statement: title.statement,
      data: relatedData
    })
  })

  return output
}

const a11yProps = index => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const index = () => {
  const theme = useTheme()

  const [value, setValue] = React.useState(0)
  const [questions, setQuestions] = React.useState([])
  const [respForm, setRespForm] = React.useState([])

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const [pdfString, setPdfString] = React.useState('')
  const [isOpenPdfModal, setIsOpenPdfModal] = React.useState(false)

  const handleChange = (_event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  const filterQuestions = (list, label) => {
    const temp = list.find(e =>
      e.group.toLowerCase().includes(label.toLowerCase())
    )

    return temp
  }

  const filterResp = questionUuid => {
    const tempData = respForm.find(e => e.question_uuid === questionUuid)

    return tempData ? tempData.option_uuid : ''
  }

  const filterEvidence = questionUuid => {
    const tempData = respForm.find(e => e.question_uuid === questionUuid)

    if (tempData && tempData?.evidence && tempData.evidence !== null) {
      return tempData?.evidence
    } else {
      return null
    }
  }

  const loadData = async () => {
    setTypeAlert('loading')
    setOpenAlert(true)

    let session = localStorage.getItem('inovaMT@form')
    session = JSON.parse(session)

    try {
      const resp = await api.get('/question/', { params: { all: true } })
      if (resp) {
        const temp = restructureData(resp.data)
        setQuestions(temp)
        setOpenAlert(false)
      }
      const resp2 = await api.get('/answer', {
        params: {
          all: true,
          atributo: 'company_uuid',
          value: session.user.company[0].uuid
        }
      })

      if (resp2 && resp2.data.length > 0) {
        setRespForm(resp2.data)
      } else {
        setMsg('Não existe respostas associadas a esse usuário!')
        setTypeAlert('error')
        setOpenAlert(true)
      }
    } catch (err) {
      setMsg('Não foi possível adquirir as Respostas!')
      setTypeAlert('error')
    }
  }

  const loadFile = (base64File, fileType) => {
    // Convert base64 to Blob
    const byteCharacters = atob(base64File)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: fileType })
  
    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob)
  
    // Open a new window
    window.open(blobUrl)
  
    // Optional: Release the memory after use
    URL.revokeObjectURL(blobUrl)
  }
  
  const handlePdfView = (evidence) => {
    if (evidence != null) {
      // setPdfString(evidence)
      // setIsOpenPdfModal(true)
      loadFile(evidence, 'application/pdf')
    }
  }

  const moveProx = () => {
    setValue(value + 1)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  const moveAnt = () => {
    setValue(value - 1)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <Box>
      {questions.length > 0 && (
        <Box sx={styles.Box}>
          <Box
            sx={{
              maxWidth: {
                xs: 320,
                sm: '100vw',
                md: '100vw',
                lg: '100vw',
                xl: '100vw'
              }
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="inherit"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Auto Diagnóstico" {...a11yProps(0)} />
                <Tab label="Conhecimento do Mercado" {...a11yProps(1)} />
                <Tab label="Liderança Transformadora e Intenção de Inovar" {...a11yProps(2)} />
                <Tab label="Gestão de Projetos e Pessoas para inovação" {...a11yProps(3)} />
                <Tab label="Gestão estratégica de tecnologias e organicidade" {...a11yProps(4)} />
                <Tab label="Avaliação de desempenho e resultados de inovação" {...a11yProps(5)} />
                {/* <Tab label="Melhoria" {...a11yProps(6)} /> */}
              </Tabs>
            </Tabs>
          </Box>

          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <>
                <Question
                  title={
                    filterQuestions(questions, 'Auto Diagnóstico')
                      .group.replace(/^\d\. /, '') || ''
                  }
                  value={
                    filterQuestions(questions, 'Auto Diagnóstico')
                      .statement || ''
                  }
                  type="text"
                />
                <br />
                {filterQuestions(questions, 'Auto Diagnóstico').data.map(
                  (q, index) => (
                    <>
                      <Question
                        key={index}
                        title={q.statement}
                        options={q.options}
                        uuid={q.uuid}
                        type="radio-zero"
                        resp={filterResp(q.uuid)}
                        setResp={() => {}}
                        typeButtonFile={true}
                        evidence={filterEvidence(q.uuid)}
                        updateEvidence={handlePdfView}
                      />
                      <br />
                    </>
                  )
                )}
              </>
            </TabPanel>

            <TabPanel value={value} index={1} dir={theme.direction}>
              <>
                <Question
                  title={
                    filterQuestions(questions, 'Conhecimento do Mercado')
                      .group || ''
                  }
                  value={
                    filterQuestions(questions, 'Conhecimento do Mercado')
                      .statement || ''
                  }
                  type="text"
                />
                <br />
                {filterQuestions(questions, 'Conhecimento do Mercado').data.map(
                  (q, index) => (
                    <>
                      <Question
                        key={index}
                        title={q.statement}
                        options={q.options}
                        uuid={q.uuid}
                        type="radio"
                        resp={filterResp(q.uuid)}
                        setResp={() => {}}
                        typeButtonFile={true}
                        evidence={filterEvidence(q.uuid)}
                        updateEvidence={handlePdfView}
                      />
                      <br />
                    </>
                  )
                )}
              </>
            </TabPanel>

            <TabPanel value={value} index={2} dir={theme.direction}>
              <>
                <Question
                  title={filterQuestions(questions, 'Liderança Transformadora e Intenção de Inovar').group || ''}
                  value={
                    filterQuestions(questions, 'Liderança Transformadora e Intenção de Inovar').statement || ''
                  }
                  type="text"
                />
                <br />
                {filterQuestions(questions, 'Liderança Transformadora e Intenção de Inovar').data.map(
                  (q, index) => (
                    <>
                      <Question
                        key={index}
                        title={q.statement}
                        options={q.options}
                        uuid={q.uuid}
                        type="radio"
                        resp={filterResp(q.uuid)}
                        setResp={() => {}}
                        typeButtonFile={true}
                        evidence={filterEvidence(q.uuid)}
                        updateEvidence={handlePdfView}
                      />
                      <br />
                    </>
                  )
                )}
              </>
            </TabPanel>

            <TabPanel value={value} index={3} dir={theme.direction}>
              <>
                <Question
                  title={filterQuestions(questions, 'Gestão de Projetos e Pessoas para inovação').group || ''}
                  value={
                    filterQuestions(questions, 'Gestão de Projetos e Pessoas para inovação').statement || ''
                  }
                  type="text"
                />
                <br />
                {filterQuestions(questions, 'Gestão de Projetos e Pessoas para inovação').data.map(
                  (q, index) => (
                    <>
                      <Question
                        key={index}
                        title={q.statement}
                        options={q.options}
                        uuid={q.uuid}
                        type="radio"
                        resp={filterResp(q.uuid)}
                        setResp={() => {}}
                        typeButtonFile={true}
                        evidence={filterEvidence(q.uuid)}
                        updateEvidence={handlePdfView}
                      />
                      <br />
                    </>
                  )
                )}
              </>
            </TabPanel>

            <TabPanel value={value} index={4} dir={theme.direction}>
              <>
                <Question
                  title={filterQuestions(questions, 'Gestão estratégica de tecnologias e organicidade').group || ''}
                  value={filterQuestions(questions, 'Gestão estratégica de tecnologias e organicidade').statement || ''}
                  type="text"
                />
                <br />
                {filterQuestions(questions, 'Gestão estratégica de tecnologias e organicidade').data.map((q, index) => (
                  <>
                    <Question
                      key={index}
                      title={q.statement}
                      options={q.options}
                      uuid={q.uuid}
                      type="radio"
                      resp={filterResp(q.uuid)}
                      setResp={() => {}}
                      typeButtonFile={true}
                      evidence={filterEvidence(q.uuid)}
                      updateEvidence={handlePdfView}
                    />
                    <br />
                  </>
                ))}
              </>
            </TabPanel>

            <TabPanel value={value} index={5} dir={theme.direction}>
              <>
                <Question
                  title={filterQuestions(questions, 'Avaliação de desempenho e resultados de inovação').group || ''}
                  value={filterQuestions(questions, 'Avaliação de desempenho e resultados de inovação').statement || ''}
                  type="text"
                />
                <br />
                {filterQuestions(questions, 'Avaliação de desempenho e resultados de inovação').data.map((q, index) => (
                  <>
                    <Question
                      key={index}
                      title={q.statement}
                      options={q.options}
                      uuid={q.uuid}
                      type="radio"
                      resp={filterResp(q.uuid)}
                      setResp={() => {}}
                      typeButtonFile={true}
                      evidence={filterEvidence(q.uuid)}
                      updateEvidence={handlePdfView}
                    />
                    <br />
                  </>
                ))}
              </>
            </TabPanel>

            {/* <TabPanel value={value} index={6} dir={theme.direction}>
              <>
                <Question
                  title={filterQuestions(questions, 'Melhoria').group || ''}
                  value={filterQuestions(questions, 'Melhoria').statement || ''}
                  type="text"
                />
                <br />
                {filterQuestions(questions, 'Melhoria').data.map((q, index) => (
                  <>
                    <Question
                      key={index}
                      title={q.statement}
                      options={q.options}
                      uuid={q.uuid}
                      type="radio"
                      resp={filterResp(q.uuid)}
                      setResp={updateOrAddObject}
                      evidence={filterEvidence(q.uuid)}
                      updateEvidence={updateEvidence}
                    />
                    <br />
                  </>
                ))}
              </>
            </TabPanel> */}
          </SwipeableViews>
        </Box>
      )}

      <Box sx={{ display: 'flex', gap: 1 }}>
        {value !== 0 ? (
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            size="large"
            type="submit"
            variant="contained"
            onClick={() => moveAnt()}
          >
            Anterior
          </Button>
        ) : (
          <></>
        )}
        {value !== 5 && (
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            size="large"
            type="submit"
            variant="contained"
            onClick={() => moveProx()}
          >
            Proximo
          </Button>
        )}
      </Box>

      <Alert
        open={openAlert}
        setOpen={setOpenAlert}
        alert={typeAlert}
        msg={msg}
        delete={() => {}}
        success={() => {
          setOpenAlert(false)
          window.location.reload()
        }}
      />

      {/* <PDFModal
        base64String={pdfString}
        isOpen={isOpenPdfModal}
        onClose={() => setIsOpenPdfModal(false)}
      /> */}
    </Box>
  )
}

export default index
