import { uniqueId } from 'lodash'

export const dataForm = [
  {
    key: uniqueId(),
    label: 'Nome',
    name: 'name',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 12
    }
  },
  {
    key: uniqueId(),
    label: 'CPF',
    name: 'cpf',
    type: 'TextField',
    mask: 'Cpf',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Email',
    name: 'email',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      md: 12
    }
  }
  // {
  //   key: uniqueId(),
  //   label: 'Senha',
  //   name: 'password',
  //   type: 'Password',
  //   mask: 'Text',
  //   validator: 'Required',
  //   measures: {
  //     xs: 12,
  //     md: 6
  //   }
  // },
  // {
  //   key: uniqueId(),
  //   label: 'Repita a senha',
  //   name: 'password2',
  //   type: 'Password',
  //   mask: 'Text',
  //   validator: 'Required',
  //   measures: {
  //     xs: 12,
  //     md: 6
  //   }
  // }
]
