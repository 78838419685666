import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import Logo from '../../assets/logo3.png'
import LogoRisc from '../../assets/LogoRisc.png'
import LogoSeciteci from '../../assets/logosecitec.png'
import LogoFapemat from '../../assets/logofapemat.png'
import LogoPT from '../../assets/logoparquetecnologico.png'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MenuIcon from '@mui/icons-material/Menu'

import { routes } from '../../routes/data'
import * as styles from './styles'

function Base(props) {
  const theme = useTheme()
  const navigate = useNavigate()

  const [open, setOpen] = React.useState(true)
  const [page, setPage] = React.useState('Auto-avaliação')
  const [isActived, setIsActived] = React.useState('')
  const [expanded, setExpanded] = React.useState('')
  const [role, setRole] = React.useState('')

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const size = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    let profileData = localStorage.getItem('inovaMT@form')
    profileData = JSON.parse(profileData)
    const tempRole = profileData.user.role_uuid
    if (tempRole) {
      setRole(tempRole)
      if (tempRole === 'manager') {
        navigate(routes.listar)
      }
    }
  }, [])

  return (
    <Container maxWidth={false} sx={styles.Container}>
      <CssBaseline />
      <styles.AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(!open)}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Premio InovaMT - {page}
          </Typography>
        </Toolbar>
      </styles.AppBar>

      <Drawer sx={styles.Drawer} variant="persistent" anchor="left" open={open}>
        <Box>
          <styles.DrawerHeader>
            <Box sx={{ width: 40 }} />
            <Box sx={styles.Logo}>
              <Box
                className="animate__animated animate__fadeIn  "
                sx={{
                  width: 150,
                  marginTop: 4,
                  marginBottom: 3,
                  borderRadius: '25%',
                  boxShadow: '3px 4px 10px #00000044'
                }}
                component="img"
                src={Logo}
              />
            </Box>
            <IconButton
              onClick={() => {
                setOpen(!open)
                setExpanded('')
              }}
            >
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon sx={{ color: 'black' }} />
              ) : (
                <ChevronRightIcon sx={{ color: 'black' }} />
              )}
            </IconButton>
          </styles.DrawerHeader>

          {role === 'participant' && (
            <>
              <Divider textAlign="center" sx={{ margin: '1rem 0 2rem 0' }}>
                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
                  FORMULÁRIOS
                </Typography>
              </Divider>

              {props.list.map(element =>
                element.children ? (
                  <ListItem
                    key={element.id}
                    sx={
                      isActived === element.text
                        ? styles.ActivedButton
                        : styles.InactivedButton
                    }
                    disablePadding
                  >
                    <ListItemButton sx={styles.NoPadding} onClick={() => {}}>
                      <styles.Accordion
                        expanded={expanded === `${element.text}`}
                        onChange={handleChange(`${element.text}`)}
                      >
                        <styles.AccordionSummary>
                          <ListItemIcon>{element.icon}</ListItemIcon>
                          <ListItemText primary={element.text} />
                        </styles.AccordionSummary>
                        <styles.AccordionDetails>
                          {element.children.map(ele => (
                            <ListItemButton
                              key={ele.id}
                              onClick={() => {
                                size ? setOpen(!open) : setOpen(open)
                                setPage(ele.text)
                                setIsActived(element.text)
                                setExpanded('')
                                navigate(ele.link)
                              }}
                            >
                              <ListItemText primary={ele.text} />
                            </ListItemButton>
                          ))}
                        </styles.AccordionDetails>
                      </styles.Accordion>
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <ListItem
                    key={element.id}
                    sx={
                      isActived === element.text
                        ? styles.ActivedButton
                        : styles.InactivedButton
                    }
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => {
                        size ? setOpen(!open) : setOpen(open)
                        setExpanded(false)
                        setPage(element.text)
                        setIsActived(element.text)
                        navigate(element.link)
                      }}
                    >
                      <ListItemIcon>{element.icon}</ListItemIcon>
                      <ListItemText primary={element.text} />
                    </ListItemButton>
                  </ListItem>
                )
              )}

              <Divider
                textAlign="center"
                sx={{ margin: '1rem 0 2rem 0', borderColor: '#29166f' }}
              >
                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
                  RESPOSTAS
                </Typography>
              </Divider>

              {props.list2.map(element =>
                element.children ? (
                  <ListItem
                    key={element.id}
                    sx={
                      isActived === element.text
                        ? styles.ActivedButton
                        : styles.InactivedButton
                    }
                    disablePadding
                  >
                    <ListItemButton sx={styles.NoPadding} onClick={() => {}}>
                      <styles.Accordion
                        expanded={expanded === `${element.text}`}
                        onChange={handleChange(`${element.text}`)}
                      >
                        <styles.AccordionSummary>
                          <ListItemIcon>{element.icon}</ListItemIcon>
                          <ListItemText primary={element.text} />
                        </styles.AccordionSummary>
                        <styles.AccordionDetails>
                          {element.children.map(ele => (
                            <ListItemButton
                              key={ele.id}
                              onClick={() => {
                                size ? setOpen(!open) : setOpen(open)
                                setPage(ele.text)
                                setIsActived(element.text)
                                setExpanded('')
                                navigate(ele.link)
                              }}
                            >
                              <ListItemText primary={ele.text} />
                            </ListItemButton>
                          ))}
                        </styles.AccordionDetails>
                      </styles.Accordion>
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <ListItem
                    key={element.id}
                    sx={
                      isActived === element.text
                        ? styles.ActivedButton
                        : styles.InactivedButton
                    }
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => {
                        size ? setOpen(!open) : setOpen(open)
                        setExpanded(false)
                        setPage(element.text)
                        setIsActived(element.text)
                        navigate(element.link)
                      }}
                    >
                      <ListItemIcon>{element.icon}</ListItemIcon>
                      <ListItemText primary={element.text} />
                    </ListItemButton>
                  </ListItem>
                )
              )}
            </>
          )}
          {/* Esta área deve ser exibida apenas para usuários administradores */}

          {role === 'manager' && (
            <>
              <Divider
                textAlign="center"
                sx={{ margin: '1rem 0 2rem 0', borderColor: '#29166f' }}
              >
                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
                  ADMINISTRADOR
                </Typography>
              </Divider>

              {props.list3.map(element =>
                element.children ? (
                  <ListItem
                    key={element.id}
                    sx={
                      isActived === element.text
                        ? styles.ActivedButton
                        : styles.InactivedButton
                    }
                    disablePadding
                  >
                    <ListItemButton sx={styles.NoPadding} onClick={() => {}}>
                      <styles.Accordion
                        expanded={expanded === `${element.text}`}
                        onChange={handleChange(`${element.text}`)}
                      >
                        <styles.AccordionSummary>
                          <ListItemIcon>{element.icon}</ListItemIcon>
                          <ListItemText primary={element.text} />
                        </styles.AccordionSummary>
                        <styles.AccordionDetails>
                          {element.children.map(ele => (
                            <ListItemButton
                              key={ele.id}
                              onClick={() => {
                                size ? setOpen(!open) : setOpen(open)
                                setPage(ele.text)
                                setIsActived(element.text)
                                setExpanded('')
                                navigate(ele.link)
                              }}
                            >
                              <ListItemText primary={ele.text} />
                            </ListItemButton>
                          ))}
                        </styles.AccordionDetails>
                      </styles.Accordion>
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <ListItem
                    key={element.id}
                    sx={
                      isActived === element.text
                        ? styles.ActivedButton
                        : styles.InactivedButton
                    }
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => {
                        size ? setOpen(!open) : setOpen(open)
                        setExpanded(false)
                        setPage(element.text)
                        setIsActived(element.text)
                        navigate(element.link)
                      }}
                    >
                      <ListItemIcon>{element.icon}</ListItemIcon>
                      <ListItemText primary={element.text} />
                    </ListItemButton>
                  </ListItem>
                )
              )}
            </>
          )}

          {role === 'specialist' && (
            <>
              <Divider
                textAlign="center"
                sx={{ margin: '1rem 0 2rem 0', borderColor: '#29166f' }}
              >
                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
                  ESPECIALISTA
                </Typography>
              </Divider>

              {props.list4.map(element =>
                element.children ? (
                  <ListItem
                    key={element.id}
                    sx={
                      isActived === element.text
                        ? styles.ActivedButton
                        : styles.InactivedButton
                    }
                    disablePadding
                  >
                    <ListItemButton sx={styles.NoPadding} onClick={() => {}}>
                      <styles.Accordion
                        expanded={expanded === `${element.text}`}
                        onChange={handleChange(`${element.text}`)}
                      >
                        <styles.AccordionSummary>
                          <ListItemIcon>{element.icon}</ListItemIcon>
                          <ListItemText primary={element.text} />
                        </styles.AccordionSummary>
                        <styles.AccordionDetails>
                          {element.children.map(ele => (
                            <ListItemButton
                              key={ele.id}
                              onClick={() => {
                                size ? setOpen(!open) : setOpen(open)
                                setPage(ele.text)
                                setIsActived(element.text)
                                setExpanded('')
                                navigate(ele.link)
                              }}
                            >
                              <ListItemText primary={ele.text} />
                            </ListItemButton>
                          ))}
                        </styles.AccordionDetails>
                      </styles.Accordion>
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <ListItem
                    key={element.id}
                    sx={
                      isActived === element.text
                        ? styles.ActivedButton
                        : styles.InactivedButton
                    }
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => {
                        size ? setOpen(!open) : setOpen(open)
                        setExpanded(false)
                        setPage(element.text)
                        setIsActived(element.text)
                        navigate(element.link)
                      }}
                    >
                      <ListItemIcon>{element.icon}</ListItemIcon>
                      <ListItemText primary={element.text} />
                    </ListItemButton>
                  </ListItem>
                )
              )}
            </>
          )}
        </Box>
        <Stack mb={2} spacing={2} alignItems="center">
          <Box sx={{ width: 100 }} component="img" src={LogoRisc} />
          <Typography>Versão 1.2.1</Typography>
        </Stack>
      </Drawer>
      <styles.Main open={open}>
        <styles.DrawerHeader />
        <Outlet />
      </styles.Main>
      <styles.FooterBar position="fixed" open={open}>
        <Toolbar>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Box sx={{ height: '48px' }} component="img" src={LogoSeciteci} />
            <Box sx={{ height: '48px' }} component="img" src={LogoPT} />
            <Box sx={{ height: '48px' }} component="img" src={LogoFapemat} />
          </Box>
        </Toolbar>
      </styles.FooterBar>
    </Container>
  )
}

export default Base
