import React, { useState } from 'react'
import Alert from '../../../components/Alert'

import { Box, Button, TextField, Typography } from '@mui/material'

import api from '../../../services/api'

const index = props => {
  const [email, setEmail] = useState('')

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const handleSubmit = async () => {
    if (email) {
      setMsg('')
      setTypeAlert('loading')
      setOpenAlert(true)

      try {
        const resp = await api.post('/forgot-my-password/', { email })
        if (resp) {
          props.setToken(resp.data.token)
          setEmail('')
          setOpenAlert(false)
          setTypeAlert('')
          props.setComponent('ValidarCodigo')
        }
      } catch (err) {
        if (err?.response?.data) {
          setMsg(err.response.data.detail[0].msg)
          setTypeAlert('error')
        }
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Typography>Informe seu Email</Typography>
        <TextField
          fullWidth
          size="small"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Button fullWidth variant="contained" onClick={handleSubmit}>
          Enviar
        </Button>
      </Box>

      <Alert
        open={openAlert}
        setOpen={setOpenAlert}
        alert={typeAlert}
        msg={msg}
        delete={() => {}}
      />
    </>
  )
}

export default index
