import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CssBaseline from '@mui/material/CssBaseline'

import api from '../../services/api'
import Alert from '../../components/Alert'
import Form from '../../components/Form'
import Termos from '../../components/Termos'
import { changeForm, validateForm } from '../../utils/functions'

import { useNavigate } from 'react-router-dom'

import { routes } from '../../routes/data'

// import Logo from '../../assets/logo.png'
import LogoAzul from '../../assets/logoazul.png'

import * as styles from './styles'
import { dataForm } from './data'

const Cadastrar = () => {
  const navigate = useNavigate()

  const [isSubmit, setIsSubmit] = React.useState(false)
  const [formData, setFormData] = React.useState({ phone: '+55'})
  const [isTermsAcept, setIsTermsAcept] = React.useState(false)

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const handleChangeForm = (name, newValue) => {
    changeForm(formData, setFormData, name, newValue)
  }

  const handleSubmit = async () => {
    setMsg('')
    setTypeAlert('loading')
    if (formData.password === formData.password2) {
      if (isTermsAcept === true) {
        const validate = validateForm(dataForm, formData)
        if (validate === false) {
          setOpenAlert(true)

          const sendData = formData
          if (sendData.cnpj) {
            sendData.cnpj = sendData.cnpj.replace(/[^0-9]/g, '').slice(0, 14)
          }
          if (sendData.phone) {
            sendData.phone = sendData.phone.replace(/[^0-9]/g, '').slice(0, 13)
          }
          if (sendData.cpf) {
            sendData.cpf = sendData.cpf.replace(/[^0-9]/g, '').slice(0, 14)
          }

          try {
            const tempResp = await api.get('/role/', { params: { all: true } })

            const tempRole = tempResp.data.find(
              e => e.role_name === 'participant'
            )

            if (tempRole) {
              await api.post('/subscription/', {
                user: {
                  name: sendData.full_name_responsible,
                  cpf: sendData.cpf,
                  email: sendData.email_responsible,
                  password: sendData.password,
                  role_uuid: tempRole.uuid
                },
                company: {
                  cnpj: sendData.cnpj,
                  phone: sendData.phone,
                  trade_name: sendData.trade_name,
                  legal_name: sendData.legal_name,
                  size: sendData.size,
                  street: sendData.street,
                  number: sendData.number,
                  district: sendData.district,
                  city: sendData.city,
                  state: sendData.state,
                  head_office_city: sendData.head_office_city,
                  business_category: sendData.business_category,
                  full_name_responsible: sendData.full_name_responsible,
                  role_responsible: sendData.role_responsible,
                  email_responsible: sendData.email_responsible
                }
              })

              setMsg('Cadastro realizado com sucesso!')
              setTypeAlert('success')
            } else {
              setMsg('Não foi possível realizar o cadastro!')
              setTypeAlert('error')
            }
          } catch (err) {
            if (err?.response?.data) {
              setMsg(err.response.data.detail[0].msg)
            } else {
              setMsg('Não foi possível realizar o cadastro!')
            }
            setTypeAlert('error')
          }
        } else {
          setIsSubmit(true)
        }
      } else {
        setTypeAlert('error')
        setMsg('Você deve aceitar os Termos e Condições!')
        setOpenAlert(true)
      }
    } else {
      setTypeAlert('error')
      setMsg('As senhas devem coincidir!')
      setOpenAlert(true)
    }
  }

  return (
    <Box sx={styles.Container}>
      <CssBaseline />
      <Box src={LogoAzul} component="img" sx={styles.Logo} />
      {/* <Box sx={styles.BoxLogoBg}>
        <Box src={Logo} component="img" sx={styles.LogoBackground} />
      </Box> */}
      <Box sx={styles.Box}>
        <Typography variant="h5" sx={styles.Login}>
          Cadastrar
        </Typography>
        {/* <Typography
          sx={{
            textAlign: 'center',
            marginBottom: '0.75rem'
          }}
        >
          <Button
            sx={styles.Link}
            href="https://www.secitec.mt.gov.br/documents/6286570/0/Regulamento+do+Premio+Inova+2023+.+pdf.pdf/2c10a24a-0b74-640b-13ed-544d9b8cfd99?t=1696612144113"
            target="_blank"
          >
            Clique aqui
          </Button>{' '}
          para confirir o Edital
        </Typography> */}
        <Form
          data={dataForm}
          isSubmit={isSubmit}
          value={formData}
          onChange={handleChangeForm}
        />
        <Termos checked={isTermsAcept} setChecked={setIsTermsAcept} />
        <Box>
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSubmit}
          >
            Cadastrar
          </Button>
          <Button
            fullWidth
            size="large"
            type="submit"
            onClick={() => navigate(routes.login)}
          >
            Voltar
          </Button>
        </Box>
      </Box>

      <Alert
        open={openAlert}
        setOpen={setOpenAlert}
        alert={typeAlert}
        msg={msg}
        success={() => {
          setOpenAlert(false)
          navigate(routes.login)
        }}
        delete={() => {}}
      />
    </Box>
  )
}

export default Cadastrar
