import React from 'react'

import Alert from '../../components/Alert'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
// import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
// import { Unstable_NumberInput as BaseNumberInput } from '@mui/base/Unstable_NumberInput'

import UploadFileIcon from '@mui/icons-material/UploadFile'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import * as styles from './styles'
import api from '../../services/api'

// const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
//   return (
//     <BaseNumberInput
//       slots={{
//         root: styles.StyledInputRoot,
//         input: styles.StyledInputElement,
//         incrementButton: styles.StyledButton,
//         decrementButton: styles.StyledButton
//       }}
//       slotProps={{
//         incrementButton: {
//           children: '▴'
//         },
//         decrementButton: {
//           children: '▾'
//         }
//       }}
//       {...props}
//       ref={ref}
//     />
//   )
// })

// Este componente tem por finalidade estruturar cada quetão do formulário separadamente
// Props:
// type -> a propriedade 'type' é responsável por definir qual é o tipo de input que será exibido
// ---- possíveis valores: 'text', 'select'
// value -> define o valor pré definido do campo
// setValue -> define a função que resgata o valor do campo
// evidence -> define o valor pré definido do campo
// setEvidence -> define a função que resgata o valor do campo
// options -> a propriedade 'options' é responsável por receber um array com valores para um 'type: select'
// title -> a propriedade 'title' é responsável por receber o titulo ou enunciado da questão

const index = props => {
  const [avaliation, setAvaliation] = React.useState(null)
  const [justify, setJustify] = React.useState('')

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const verificValue = () => {
    const selectedIndex = props.options?.findIndex(
      options => options.uuid === props.resp
    )
    return selectedIndex > 1
  }

  const handleDownload = () => {
    if (props.evidence) {
      // Converte a string Base64 em um Blob
      const byteCharacters = atob(props.evidence)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: 'application/pdf' })

      // Cria um Object URL e aciona um evento de clique para fazer o download
      const objectUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = objectUrl
      a.download = `${Date.now().toString()}.pdf`
      a.click()
      URL.revokeObjectURL(objectUrl)
    }
  }

  const handleEdit = async () => {
    if (
      avaliation !== null &&
      justify !== '' &&
      avaliation !== props.avaliationValue
    ) {
      try {
        setMsg('')
        setTypeAlert('loading')
        setOpenAlert(true)

        await api.put(
          '/specialistevaluation',
          {
            evaluation: avaliation,
            editing_reason: justify
          },
          { params: { uuid: props.evaluationUUid } }
        )

        setMsg('Nota Alterada com sucesso!')
        setTypeAlert('success')
      } catch (err) {
        console.log(err)
        setMsg('Não foi possível Atualizar a Nota!')
        setTypeAlert('error')
        setOpenAlert(true)
      }
    } else {
      setMsg(
        'A nota deve ser diferente da anterior e o campo de justificação deve ser preenchido'
      )
      setTypeAlert('error')
      setOpenAlert(true)
    }
  }

  if (props.type === 'text') {
    return (
      <Box sx={styles.Question}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {props.title}
        </Typography>
        <Typography>{props.value}</Typography>
      </Box>
    )
  } else if (props.type === 'select') {
    return (
      <Box sx={styles.Question}>
        <Typography>{props.title}</Typography>
        <Box sx={styles.Input}>
          <FormControl fullWidth size="small">
            <Select label={props.label}>
              {props.options.map(data => (
                <MenuItem value={data.uuid} key={data.uuid}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            component="label"
            startIcon={<CloudUploadIcon />}
            variant="contained"
          >
            Subir Evidência (PDF)
            <styles.InputFile
              onClick={e => props.setEvidence(e.target.value)}
              type="file"
            />
          </Button>
          <IconButton color={props.evidence === true ? 'success' : 'default'}>
            <UploadFileIcon />
          </IconButton>
        </Box>
      </Box>
    )
  } else if (props.type === 'radio') {
    return (
      <Box sx={styles.Question}>
        <Typography sx={styles.QuestionTitle}>{props.title}</Typography>
        <Box sx={styles.Input}>
          <FormControl
            sx={{ color: 'black' }}
            fullWidth
            size="small"
          >
            <RadioGroup
              value={props.resp}
              onChange={e => {
                props.setResp(e.target.value, props.uuid, props.options)
              }}
            >
              {props?.options &&
                props.options.map(data => (
                  <FormControlLabel
                    value={data.uuid}
                    control={<Radio />}
                    label={data.text}
                    key={data.uuid}
                  />
                ))}
            </RadioGroup>
            {verificValue() && !props?.evidence && props.evidence === null ? (
              <Typography>
                Para esta alternativa é necessário subir uma evidência
              </Typography>
            ) : (
              <div></div>
            )}
          </FormControl>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ display: 'flex' }}>
              {props?.typeButtonFile && props.typeButtonFile === true ? (
                <Button
                  component="label"
                  startIcon={<CloudUploadIcon />}
                  variant="contained"
                  disabled={props.evidence == null || props.evidence === ''}
                  onClick={() => props.updateEvidence(props.evidence)}
                >
                  Visualizar Evidência
                </Button>
              ) : (
                <Button
                  component="label"
                  startIcon={<CloudUploadIcon />}
                  variant="contained"
                >
                  Subir Evidência (PDF)
                  <styles.InputFile
                    onChange={e =>
                      props.updateEvidence(e.target.files[0], props.uuid)
                    }
                    type="file"
                  />
                </Button>
              )}
              <IconButton
                color={
                  props?.evidence && props.evidence !== null
                    ? 'success'
                    : 'default'
                }
                onClick={handleDownload}
              >
                <UploadFileIcon />
              </IconButton>
            </Box>
            <Box sx={{ margin: '15px 0' }}>
              <Typography sx={{ paddingBottom: 1 }}>
                Nota de Avaliação
              </Typography>
              {/* <NumberInput
                aria-label="Demo number input"
                placeholder="Entre com a Nota"
                value={avaliation || props.avaliationValue}
                onChange={e => setAvaliation(e.target.value)}
              /> */}
              <input
                placeholder="Entre com a Nota"
                type="number"
                style={{
                  fontSize: '0.875rem',
                  fontFamily: 'inherit',
                  width: '90%',
                  fontWeight: 400,
                  lineHeight: 1.5,
                  gridColumn: 1 / 2,
                  gridRow: 1 / 3,
                  color: '#9e9e9e',
                  background: 'inherit',
                  border: '1px solid #9e9e9e',
                  borderRadius: '4px',
                  padding: '8px 12px'
                }}
                min="1"
                max="5"
                onWheel={(e) => e.target.blur()}
                disabled={props?.disabled || false}
                value={avaliation || props.avaliationValue}
                onChange={e => setAvaliation(e.target.value)}
              />
              <Button
                variant="contained"
                sx={{ color: 'white', marginTop: 2 }}
                onClick={handleEdit}
              >
                Modificar
              </Button>
            </Box>
          </Box>
        </Box>
        <Divider textAlign="center" sx={{ margin: '1rem 0 1rem 0' }} />
        <Typography sx={{ paddingBottom: 1 }}>
          Justificativa de Alteração
        </Typography>
        <styles.Textarea
          aria-label="minimum height"
          minRows={3}
          placeholder="Razões que justificam alterações na nota dessa questão após a primeira avaliação"
          value={justify || props.justify}
          onChange={e => setJustify(e.target.value)}
        />
        <Divider textAlign="center" sx={{ margin: '1rem 0 2rem 0' }} />
        <Alert
          open={openAlert}
          setOpen={setOpenAlert}
          alert={typeAlert}
          msg={msg}
          delete={() => {}}
          success={() => {
            setOpenAlert(false)
            window.location.reload()
          }}
        />
      </Box>
    )
  } else if (props.type === 'radio-zero') {
    return (
      <Box sx={styles.Question}>
        <Typography sx={styles.QuestionTitle}>{props.title}</Typography>
        <Box sx={styles.Input}>
          <FormControl
            sx={{ color: 'black' }}
            fullWidth
            size="small"
          >
            <RadioGroup
              value={props.resp}
              onChange={e => {
                props.setResp(e.target.value, props.uuid, props.options)
              }}
            >
              {props?.options &&
                props.options.map(data => (
                  <FormControlLabel
                    value={data.uuid}
                    control={<Radio />}
                    label={data.text}
                    key={data.uuid}
                  />
                ))}
            </RadioGroup>
            {verificValue() && !props?.evidence && props.evidence === null ? (
              <Typography>
                Para esta alternativa é necessário subir uma evidência
              </Typography>
            ) : (
              <div></div>
            )}
          </FormControl>
        </Box>
        <Divider textAlign="center" sx={{ margin: '1rem 0 1rem 0' }} />
  
        <Alert
          open={openAlert}
          setOpen={setOpenAlert}
          alert={typeAlert}
          msg={msg}
          delete={() => {}}
          success={() => {
            setOpenAlert(false)
            window.location.reload()
          }}
        />
      </Box>
    )
  }
}

export default index
