export const Cpf = value => {
  let resp = { err: false, value }
  value = value.replace(/[^0-9]/g, '')
  const aux = value.split('')
  if (aux.length === 0) {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value: aux.join('')
    }
  } else {
    let d1 = 0
    for (let i = 0; i < 9; i++) {
      d1 += parseInt(aux[i]) * (10 - i)
    }
    d1 = d1 % 11
    if (d1 < 2) {
      d1 = 0
    } else {
      d1 = 11 - d1
    }
    let d2 = 0
    for (let i = 0; i < 10; i++) {
      d2 += parseInt(aux[i]) * (11 - i)
    }
    d2 = d2 % 11
    if (d2 < 2) {
      d2 = 0
    } else {
      d2 = 11 - d2
    }
    if (aux[9] !== d1.toString() || aux[10] !== d2.toString()) {
      resp = {
        err: true,
        msg: 'Cpf Inválido',
        value: aux.join('')
      }
    }
  }
  return resp
}

export const Cnpj = value => {
  const resp = { err: false, value }
  value = value.replace(/[^0-9]/g, '')

  // Trunca o valor para ter no máximo 14 dígitos
  value = value.substr(0, 14)

  if (value.length === 0) {
    return {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  }

  if (value.length !== 14) {
    return {
      err: true,
      msg: 'Valor Inválido',
      value
    }
  }

  // Verificar se todos os dígitos são iguais
  const areAllDigitsEqual = value.split('').every(digit => digit === value[0])
  if (areAllDigitsEqual) {
    return {
      err: true,
      msg: 'CNPJ Inválido',
      value
    }
  }

  const dv1 = calculateDigit(value, [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2])
  const dv2 = calculateDigit(value, [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2])

  if (value[12] !== String(dv1) || value[13] !== String(dv2)) {
    return {
      err: true,
      msg: 'CNPJ inválido',
      value
    }
  }

  return resp
}

const calculateDigit = (value, weights) => {
  let sum = 0
  for (let i = 0; i < weights.length; i++) {
    sum += parseInt(value[i]) * weights[i]
  }
  const result = sum % 11
  return result < 2 ? 0 : 11 - result
}

export const Phone = value => {
  let resp = { err: false, value }
  value = value.replace(/[^0-9]/g, '')
  if (value.split('').length < 3) {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  } else {
    if (value.split('').length < 12) {
      resp = {
        err: true,
        msg: 'Número Inválido',
        value
      }
    }
  }
  return resp
}

export const Number = value => {
  let resp
  if (value.split.length > 0) {
    resp = {
      err: false,
      value
    }
  } else {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  }
  return resp
}

export const Required = value => {
  let resp = { err: false, value }
  if (value === '') {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  }
  return resp
}

export const Opcional = value => {
  const resp = { err: false, value }
  return resp
}
