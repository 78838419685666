import { colors } from '../../core/stylesConfig'
import { styled } from '@mui/material/styles'

export const Question = {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'justify'
}

export const Input = {
  display: 'flex',
  flexDirection: {
    xs: 'column',
    sm: 'row'
  },
  gap: 1,
  '& .MuiButton-root': {
    height: '40px',
    backgroundColor: colors.primary.main,
    ':hover': {
      backgroundColor: colors.primary.dark
    },
    width: {
      xs: '100%',
      sm: '300px'
    }
  },
  '& .MuiIconButton-root': {
    height: '40px',
    width: '40px',
    margin: {
      xs: '0 calc(50% - 20px)',
      sm: 0
    }
  }
}

export const InputFile = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

export const QuestionTitle = {
  fontWeight: '600',
  paddingBottom: 1,
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  marginBottom: 1
}
