import React, { useState } from 'react'
import axios from 'axios'

import { Box, Button, TextField, Typography } from '@mui/material'

import Alert from '../../../components/Alert'

const index = props => {
  const [code, setCode] = useState('')

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const handleSubmitCode = async () => {
    if (code !== '') {
      try {
        setMsg('')
        setTypeAlert('loading')
        setOpenAlert(true)

        const resp = await axios.post(
          `${process.env.REACT_APP_API_URL}/forgot-my-password/code/`,
          { code },
          {
            headers: {
              Authentication: props.token
            }
          }
        )

        if (resp) {
          props.setToken(resp.data.token)
          setCode('')
          setOpenAlert(false)
          setTypeAlert('')
          props.setComponent('NovaSenha')
        }
      } catch (err) {
        if (err?.response?.data) {
          setMsg(err.response.data.detail[0].msg)
          setTypeAlert('error')
        }
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Typography>Digite o código enviado para o seu email</Typography>
        <TextField
          fullWidth
          size="small"
          value={code}
          onChange={e => setCode(e.target.value)}
        />
        <Button fullWidth variant="contained" onClick={handleSubmitCode}>
          Validar
        </Button>
      </Box>

      <Alert
        open={openAlert}
        setOpen={setOpenAlert}
        alert={typeAlert}
        msg={msg}
        delete={() => {}}
      />
    </>
  )
}

export default index
