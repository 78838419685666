import ArticleIcon from '@mui/icons-material/Article'
import DoorBack from '@mui/icons-material/DoorBack'
import Person from '@mui/icons-material/Person'

import { uniqueId } from 'lodash'

import { routes } from '../../routes/data'

import { colors } from '../../core/stylesConfig'

export const menuList = [
  {
    id: uniqueId(),
    text: 'Formulário de Inscrição',
    icon: <ArticleIcon sx={{ color: colors.primary.green }} />,
    link: routes.formulario
  },
  {
    id: uniqueId(),
    text: 'Sair',
    icon: <DoorBack sx={{ color: colors.primary.green }} />,
    link: routes.login
  }
]

export const opList = [
  {
    id: uniqueId(),
    text: 'Minha Inscrição',
    icon: <ArticleIcon sx={{ color: colors.primary.green }} />,
    link: routes.formfull
  }
]

export const specialist = [
  {
    id: uniqueId(),
    text: 'Minhas Atribuições',
    icon: <ArticleIcon sx={{ color: colors.primary.green }} />,
    link: routes.avaliar
  },
  {
    id: uniqueId(),
    text: 'Minhas Avaliações',
    icon: <ArticleIcon sx={{ color: colors.primary.green }} />,
    link: routes.avaliadas
  },
  {
    id: uniqueId(),
    text: 'Sair',
    icon: <DoorBack sx={{ color: colors.primary.green }} />,
    link: routes.login
  }
]

export const admList = [
  {
    id: uniqueId(),
    text: 'Cadastrar Avaliador',
    icon: <Person sx={{ color: colors.primary.green }} />,
    link: routes.cadastrar_user
  },
  {
    id: uniqueId(),
    text: 'Lista de Respostas',
    icon: <ArticleIcon sx={{ color: colors.primary.green }} />,
    link: routes.listar
  },
  {
    id: uniqueId(),
    text: 'Respostas Atribuidas',
    icon: <ArticleIcon sx={{ color: colors.primary.green }} />,
    link: routes.listar_atribuidas
  },
  {
    id: uniqueId(),
    text: 'Ranking',
    icon: <ArticleIcon sx={{ color: colors.primary.green }} />,
    link: routes.ranking
  },
  {
    id: uniqueId(),
    text: 'Sair',
    icon: <DoorBack sx={{ color: colors.primary.green }} />,
    link: routes.login
  }
]
