import React from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Orientation from './orientation'
import * as styles from './styles'

const index = (props) => {
  const [orientationControl, setOrientationControl] = React.useState(false)

  const next = () => {
    setOrientationControl(true)
    props.setControl(true)
  }

  return (
    orientationControl === false ? (
      <Box>
        <Typography variant="h5">Boas Vindas</Typography>
        <Box sx={styles.Box}>
          <Typography>
            Você responderá a um questionário fundamentado na estrutura e conceitos 
            da Norma ABNT NBR ISO 56002:2020 de Sistemas de Gestão de Inovação.
          </Typography>
          <Typography>
            Os elementos que constituem o AUTODIAGNÓSTICO foram organizados segundo 
            os Fundamentos determinantes da Capacidade de Inovação de uma empresa.
          </Typography>
          <Typography>
            O preenchimento do autodiagnóstico permitirá à empresa, além de verificar 
            a aderência de suas práticas de gestão de inovação à norma técnica, conhecer 
            também a capacidade de inovação da empresa a partir do atendimento às cláusulas 
            da norma relacionadas ao fator de capacidade. 
          </Typography>
          <Typography>
            A avaliação da maturidade do sistema de gestão de inovação segue a 
            ESCALA de 1 a 5, onde: 
          </Typography>
          <Typography sx={{ margin: '0 0 0 8px' }}>
            1 - Refere-se a níveis INFORMAIS de realização das práticas. 
          </Typography>
          <Typography sx={{ margin: '0 0 0 8px' }}>
            2 - Refere-se a níveis BÁSICOS de realização das práticas.
          </Typography>
          <Typography sx={{ margin: '0 0 0 8px' }}>
            3 - Refere-se a níveis PROATIVOS/ESTRUTURADOS de realização das práticas.
          </Typography>
          <Typography sx={{ margin: '0 0 0 8px' }}>
            4 - Refere-se a níveis FORMAIS estabelecidos de realização das práticas.
          </Typography>
          <Typography sx={{ margin: '0 0 0 8px' }}>
            5 - Refere-se a níveis SISTEMÁTICOS DE MELHORIA CONTINUADA de realização 
            das práticas.
          </Typography>
          <Typography sx={{ fontWeight: '600' }}>
            Lei Geral de Proteção de Dados:
          </Typography>
          <Typography sx={{ margin: '0 0 0 8px' }}>
            Para este formulário, será necessário a coleta de dados pessoais e 
            dados sensíveis dos representantes e da empresa. Essas informações 
            serão armazenadas nas bases de dados das instituições governamentais 
            responsáveis pela iniciativa: SECITECI-MT, Parque Tecnológico de 
            Mato Grosso e FAPEMAT. 
          </Typography>
          <Typography sx={{ margin: '0 0 0 8px' }}>
            Os dados armazenados serão usados para fins referentes às etapas do 
            prêmio, que prevê a geração de relatórios e devolutivas de apontamentos 
            e melhorias nos processos de inovação da empresa.
          </Typography>
          <Button
              fullWidth
              sx={{ marginTop: 1 }}
              size="large"
              type="submit"
              variant="contained"
              onClick={next}
            >
              Orientações
            </Button>
        </Box>
      </Box> 
    ): (
      <Orientation />
    )
  )
}

export default index
