import MuiGrid from '@mui/material/Grid'

import * as Fields from './Fields'
import { DependentSelects } from './Fields'
import { citiesData } from '../../utils/citiesData'

function Form(props) {
  return (
    <MuiGrid container spacing={1} sx={{ marginBottom: 1 }}>
      {props.data.map((element, index) => {
        if (element.type === 'Dependency') {
          return (
            <DependentSelects
              key={`dependent-select-${index}`} // Adicionando uma key para mapeamento
              citiesData={citiesData}
              onChange={props.onChange}
              stateField={{
                key: 'state',
                name: 'state',
                label: 'Estados',
                measures: { xs: 6 },
                validator: 'Required'
              }}
              cityField={{
                key: 'city',
                name: 'city',
                label: 'Cidades',
                measures: { xs: 6 },
                validator: 'Required'
              }}
              isSubmit={props.isSubmit} // Usamos diretamente a propriedade isSubmit
            />
          )
        } else {
          return Fields[element.type]({
            key: `field-${index}${element.key}`, // Adicionando uma key para mapeamento
            field: element,
            isSubmit: props.isSubmit,
            value: props.value[element?.name] || '',
            onChange: props.onChange
          })
        }
      })}
    </MuiGrid>
  )
}

export default Form
