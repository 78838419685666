import React from 'react'
import {
  DataGrid,
  GridToolbar,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  gridClasses
  // ptBR
} from '@mui/x-data-grid'

import { colors } from '../../core/stylesConfig'
import Pagination from '@mui/material/Pagination'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { alpha, styled } from '@mui/material/styles'

import * as styles from './styles'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: colors.primary.main,
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(colors.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: theme.palette.grey[200]
      }
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            colors.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          )
        }
      }
    }
  }
}))

const index = props => {
  function CustomPagination() {
    const apiRef = useGridApiContext()
    const page = useGridSelector(apiRef, gridPageSelector)
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)

    return (
      <Pagination
        count={pageCount}
        page={page + 1}
        onChange={(_event, value) => apiRef.current.setPage(value - 1)}
      />
    )
  }

  const altura = document.getElementById('root').clientHeight - 250

  return (
    <Box>
      <Typography variant="h5">Listar</Typography>
      <Box sx={styles.Box}>
        <Typography
          sx={{
            textAlign: 'center',
            height: altura,
            width: '100%'
          }}
        >
          <StripedDataGrid
            rows={props.rows}
            columns={props.columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            components={{
              Toolbar: GridToolbar,
              Pagination: CustomPagination
            }}
            // localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            getRowId={row => row.uuid}
          />
        </Typography>
      </Box>
    </Box>
  )
}

export default index
