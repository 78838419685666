import { colors } from '../../core/stylesConfig'
import LowPoly from '../../assets/lowpolyAzul.png'

export const Container = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '98vh',
  alignItems: 'center',
  justifyContent: 'center'
}

export const Box = {
  backgroundImage: `url(${LowPoly})`,
  backgroundSize: 'cover',
  borderRadius: 3,
  boxShadow: `${colors.shadow.min}`,
  margin: '0px 20px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: {
    xs: 'column',
    sm: 'row'
  }
}

export const BoxLogin = {
  maxWidth: {
    xs: '400px',
    md: '320px',
    lg: '400px'
  },
  width: {
    xs: '90vw',
    sm: '48vw'
  },
  backgroundColor: `${colors.common.white}`,
  borderRadius: {
    xs: '12px 12px 12px 12px',
    sm: '12px 0px 0px 12px'
  },
  padding: {
    xs: '15px 20px',
    sm: '20px 35px'
  }
}

export const BoxSuperLogo = {
  maxWidth: {
    xs: '400px',
    md: '320px',
    lg: '400px'
  },
  width: {
    xs: '90vw',
    sm: '48vw'
  },
  height: '100%',
  display: {
    xs: 'none',
    sm: 'flex'
  },
  justifyContent: 'center'
}

export const Login = {
  fontSize: '1.5rem',
  marginBottom: '0.5rem'
}

export const Input = {
  marginBottom: '20px'
}

export const LogoBackground = {
  position: 'absolute',
  bottom: '10%',
  left: '-8%',
  width: 'auto',
  height: '60%',
  zIndex: '-1',
  filter: 'blur(8px)',
  overflow: 'hidden',
  objectFit: 'center'
}

export const Logo = {
  position: 'absolute',
  top: '15px',
  left: '15px',
  width: 'auto',
  height: '50px'
}

export const LinkBox = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
}

export const Link = {
  color: `${colors.common.black}`,
  fontSize: '0.75rem',
  padding: '0.1rem 0.5rem',
  borderRadius: 1,
  marginBottom: '0.5rem',
  textTransform: 'initial',
  textDecoration: 'none',
  cursor: 'pointer',
  transition: '0.3s',
  ':hover': {
    backgroundColor: colors.primary.light
  }
}

export const BoxLogoBg = {
  position: 'absolute',
  top: 0,
  maxWidth: '100vw',
  maxHeight: '100vh',
  width: '100%',
  zIndex: '-1',
  height: '100%',
  overflow: 'hidden'
}

export const BoxLogoForm = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '5px'
}

export const LogoForm = {
  height: {
    xs: '80px',
    md: '60px',
    lg: '80px'
  },
  width: 'auto'
}

export const LogoRisc = {
  width: '30%',
  height: 'auto',
  marginTop: 1
}

export const SuperLogo = {
  height: 'auto',
  width: '80%',
  margin: '10%'
}
