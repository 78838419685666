import axios from 'axios'

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
})

api.interceptors.request.use(
  config => {
    const dataUser = localStorage.getItem('inovaMT@form')
    if (dataUser) {
      const tempUser = JSON.parse(dataUser)
      config.headers.Authentication = tempUser.token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default api
